<template>
    <div class="p-2">
        <p class="m-5">
            {{ $t("Choose add sensor method") }}
        </p>
        <div class="tile is-ancestor">
            <div
                class="tile is-6 is-parent"
                v-for="(item, index) in addMethods"
                :key="index"
            >
                <div
                    @click="item.onClick"
                    class="button is-flex mycard is-flex-grow-1"
                >
                    <p class="is-size-3">
                        {{ item.name }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProfileAddSensorInstruction",
    data() {
        return {
            addMethods: [
                {
                    id: "1",
                    name: "QR Code",
                    onClick: () => {
                        console.log("qrcode");
                        this.$router.push({
                            name: "ProfileAddSensorByQRCode",
                        });
                    },
                },
                // {
                //     id: "2",
                //     name: "Manual",
                //     onClick: () => {
                //         console.log("manual");
                //     },
                // },
            ],
        };
    },
};
</script>

<style scoped>
.mycard {
    /* border: 4px solid red; */
    min-height: 100px;
}
</style>
