<template>
  <div class="modal-card" style="width: auto">
    <header
      class="modal-card-head tw-text-lg tw-flex tw-gap-4 tw-justify-center"
    >
      <p>{{ $t("Setting") }}</p>
      <input
        ref="deviceName"
        class="tw-bg-transparent tw-text-lg tw-text-center"
        type="text"
        :disabled="!editMode"
        v-model="localName"
      />
      <button
        v-if="editMode"
        class=" tw-p-2 tw-text-white tw-bg-gray-500 tw-rounded-full tw-right-2"
        type="button"
        @click="onSaveNewName"
      >
        <svg
          class="tw-w-4 tw-h-4"
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
          />
        </svg>
      </button>
      <button
        v-else
        class=" tw-p-2 tw-text-white tw-bg-gray-500 tw-rounded-full tw-right-2"
        type="button"
        @click="onEditMode"
      >
        <svg
          class="tw-w-4 tw-h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path
            d="M421.7 220.3L188.5 453.4L154.6 419.5L158.1 416H112C103.2 416 96 408.8 96 400V353.9L92.51 357.4C87.78 362.2 84.31 368 82.42 374.4L59.44 452.6L137.6 429.6C143.1 427.7 149.8 424.2 154.6 419.5L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3zM492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75z"
          />
        </svg>
      </button>
      <!-- <p class="tw-text-md">{{ $t("Setting") }} {{ thingID }}</p> -->
    </header>
    <section class="modal-card-body">
      <b-collapse
        :open="false"
        class="card setting-topic"
        animation="slide"
        aria-id="contentIdForA11y3"
        v-if="calibrateFeature.DO || calibrateFeature.pH"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">การคาลิเบรท</p>
            <a class="card-header-icon">
              <b-icon pack="fas" :icon="props.open ? 'angle-up' : 'angle-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content" style="text-align: left">
          <div v-if="calibratingPond === 0" class="is-mobile mb-2 mx-2">
            <p style="padding-bottom: 1rem">โปรดเลือกบ่อที่ต้องการทำการคาลิเบรท</p>
            <div class="level is-mobile">
              <b-button
                v-for="index in 4"
                :key="index"
                @click="onSelectCalibratingPond(index)"
                class="level-item"
                type="is-light"
                >บ่อที่ {{ index }}</b-button
              >
            </div>
          </div>
          <div v-else class="is-mobile mb-2 mx-2">
            <p style="padding-bottom: 1rem">คุณกำลังทำการคาลิเบรทบ่อที่ {{ calibratingPond }} <strong @click="onSelectCalibratingPond(0)" style="text-decoration: underline; cursor: pointer;">เปลี่ยนบ่อ</strong></p>
            <div class="is-mobile level">
              <b-button
                v-if="calibrateFeature.DO"
                @click="onOpenCalibrateDO"
                class="level-item"
                type="is-light"
                >Calibrate DO</b-button
              >
              <b-button
                v-if="calibrateFeature.pH"
                @click="onOpenCalibratePH"
                class="level-item"
                type="is-light"
                >Calibrate pH</b-button
              >
            </div>
          </div>
        </div>
      </b-collapse>
      <b-collapse
        :open="false"
        class="card setting-topic"
        animation="slide"
        aria-id="contentIdForA11y3"
        v-if="flushIntervalFeature"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">ตั้งระยะเวลาปั้มน้ำสะอาดขึ้นมาล้าง</p>
            <a class="card-header-icon">
              <b-icon pack="fas" :icon="props.open ? 'angle-up' : 'angle-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            <div class="description">
              กำหนดเวลาปั้มน้ำสะอาดขึ้นมาล้าง (ค่าเดียวใช้ทุกบ่อ)
            </div>
            <b-field label="ตั้งเวลา (นาที)">
              <b-numberinput min="0" max="255" v-model="setting.PondFlush">
              </b-numberinput>
            </b-field>
          </div>
        </div>
        <footer class="card-footer">
          <a @click="onApplyPondFlush" class="card-footer-item">{{
            $t("Apply")
          }}</a>
        </footer>
      </b-collapse>
      <b-collapse
        :open="false"
        class="card setting-topic"
        animation="slide"
        aria-id="contentIdForA11y3"
        v-if="pondControlFeature"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">เปิด/ปิดการทำงานแต่ละบ่อ</p>
            <a class="card-header-icon">
              <b-icon pack="fas" :icon="props.open ? 'angle-up' : 'angle-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            <b-field v-for="(item, index) in items" :key="index">
              <b-switch
                v-model="item.status"
                :true-value="1"
                :false-value="0"
                type="is-success"
              ></b-switch>
              <span>{{ item.name }}</span>
            </b-field>
          </div>
        </div>
        <!-- <footer class="card-footer">
          <a class="card-footer-item"></a>
        </footer> -->
        <footer class="card-footer">
          <a class="card-footer-item" @click="onApplyPondEn">{{
            $t("Apply")
          }}</a>
        </footer>
      </b-collapse>
      <b-collapse
        :open="false"
        class="card setting-topic"
        animation="slide"
        aria-id="contentIdForA11y3"
        v-if="pumpIntervalFeature.length > 0"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">
              ตั้งค่าระยะเวลาปั้มน้ำจากบ่อขึ้นมาวัด
            </p>
            <a class="card-header-icon">
              <b-icon pack="fas" :icon="props.open ? 'angle-up' : 'angle-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            <b-collapse
              :open="false"
              class="card setting-topic"
              animation="slide"
              aria-id="contentIdForA11y3"
              v-for="(pond, index) in pumpIntervalFeature"
              :key="index"
            >
              <template #trigger="props">
                <div
                  class="card-header"
                  role="button"
                  aria-controls="contentIdForA11y3"
                  :aria-expanded="props.open"
                >
                  <p class="card-header-title" style="margin: 0">
                    ตั้งเวลา บ่อที่{{ pond.id }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon
                      pack="fas"
                      :icon="props.open ? 'angle-up' : 'angle-down'"
                    >
                    </b-icon>
                  </a>
                </div>
              </template>

              <div class="card-content">
                <b-field label="ตั้งเวลา (นาที)">
                  <b-numberinput
                    min="0"
                    max="255"
                    v-model="items[pond.id - 1].PondSoob"
                  >
                  </b-numberinput>
                </b-field>
              </div>
            </b-collapse>
          </div>
        </div>
        <footer class="card-footer">
          <a @click="onApplyPondSoob" class="card-footer-item">{{
            $t("Apply")
          }}</a>
        </footer>
      </b-collapse>
      <b-collapse
        :open="false"
        class="card setting-topic"
        animation="slide"
        aria-id="contentIdForA11y3"
        v-if="warningFeature.length > 0"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">ตั้งค่าการแจ้งเตือน</p>
            <a class="card-header-icon">
              <b-icon pack="fas" :icon="props.open ? 'angle-up' : 'angle-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content" style="text-align: left">
          <b-field class="level">
            <!-- <b-checkbox
              v-model="setting.LocTAWarn"
              :true-value="1"
              :false-value="0"
            >
              {{ $t("Air Temperature Warning") }}
            </b-checkbox> -->
            <div class="level-left">
              <span class="level-items">{{
                $t("Air Temperature Warning")
              }}</span>
              <b-numberinput
                style="padding-left: 10px"
                class="level-item"
                :controls="false"
                step="0.1"
                size="is-small"
                v-model="setting.LocTAWarn"
              ></b-numberinput>
            </div>
          </b-field>
          <b-field class="level">
            <!-- <b-checkbox
              v-model="setting.LocHumidWarn"
              :true-value="1"
              :false-value="0"
              >{{ $t("Humidity Warning") }}</b-checkbox
            > -->
            <div class="level-left">
              <span class="level-items">{{ $t("Humidity Warning") }}</span>
              <b-numberinput
                style="padding-left: 10px"
                class="level-item"
                :controls="false"
                step="0.1"
                size="is-small"
                v-model="setting.LocHumidWarn"
              ></b-numberinput>
            </div>
          </b-field>
          <b-collapse
            :open="false"
            class="card setting-topic"
            animation="slide"
            aria-id="contentIdForA11y3"
            v-for="(pond, index) in warningFeature"
            :key="index"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open"
              >
                <p class="card-header-title" style="margin: 0">
                  ตั้งค่าการแจ้งเตือน บ่อที่ {{ pond.id }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'angle-up' : 'angle-down'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content">
              <!-- <b-field label="DO Start Aerator" horizontal>
                <b-input></b-input>
              </b-field>
              <b-field label="DO Stop Aerator" horizontal>
                <b-input></b-input>
              </b-field> -->
              <b-field class="level">
                <!-- <b-checkbox
                  v-model="pond.PondDOWarn0"
                  :true-value="1"
                  :false-value="0"
                  >{{ $t("DO Warning") }}</b-checkbox
                > -->
                <div class="level-left">
                  <span class="level-items">{{ $t("DO Warning") }}</span>
                  <b-numberinput
                    style="padding-left: 10px"
                    class="level-item"
                    :controls="false"
                    step="0.1"
                    size="is-small"
                    controls-position="compact"
                    v-model="items[parseInt(pond.id) - 1].PondDOWarn"
                  ></b-numberinput>
                </div>
              </b-field>
              <b-field class="level">
                <!-- <b-checkbox
                  v-model="pond.PondPhWarn"
                  :true-value="1"
                  :false-value="0"
                  >{{ $t("pH Warning") }}</b-checkbox
                > -->
                <div class="level-left">
                  <span class="level-items">{{ $t("pH Warning") }}</span>
                  <b-numberinput
                    style="padding-left: 10px"
                    class="level-item"
                    :controls="false"
                    step="0.1"
                    size="is-small"
                    controls-position="compact"
                    v-model="items[parseInt(pond.id) - 1].PondPhWarn"
                  ></b-numberinput>
                </div>
              </b-field>
              <b-field class="level">
                <!-- <b-checkbox
                  v-model="pond.PondTWWarn"
                  :true-value="1"
                  :false-value="0"
                  >{{ $t("Watertemp Warning") }}</b-checkbox
                > -->
                <div class="level-left">
                  <span class="level-items">{{ $t("Watertemp Warning") }}</span>
                  <b-numberinput
                    style="padding-left: 10px"
                    class="level-item"
                    :controls="false"
                    step="0.1"
                    size="is-small"
                    controls-position="compact"
                    v-model="items[parseInt(pond.id) - 1].PondTWWarn"
                  ></b-numberinput>
                </div>
              </b-field>
              <b-field class="level">
                <!-- <b-checkbox
                  v-model="pond.PondECWarn"
                  :true-value="1"
                  :false-value="0"
                  >{{ $t("EC Warning") }}</b-checkbox
                > -->
                <div class="level-left">
                  <span class="level-items">{{ $t("EC Warning") }}</span>
                  <b-numberinput
                    style="padding-left: 10px"
                    class="level-item"
                    :controls="false"
                    step="0.1"
                    size="is-small"
                    controls-position="compact"
                    v-model="items[parseInt(pond.id) - 1].PondECWarn"
                  ></b-numberinput>
                </div>
              </b-field>
              <!-- <b-field :label="`Warn when DO lower than` | t">
                <b-numberinput step="1" :controls="false" min="0" max="20">
                </b-numberinput>
              </b-field> -->
            </div>
            <footer v-if="warningFeature.length > 1" class="card-footer">
              <a
                @click="onCopyWarningValue(parseInt(pond.id))"
                class="card-footer-item"
                >{{ $t("Copy value") }}</a
              >
            </footer>
          </b-collapse>
        </div>
        <footer class="card-footer">
          <a @click="onApplyWarn" class="card-footer-item">{{ $t("Apply") }}</a>
        </footer>
      </b-collapse>
      <b-collapse
        :open="false"
        class="card setting-topic"
        animation="slide"
        aria-id="contentIdForA11y3"
        v-if="aeratorControlFeature.length > 0"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">
              ตั้งค่าโหมดการทำงานของเครื่องเติมอากาศ
            </p>
            <a class="card-header-icon">
              <b-icon pack="fas" :icon="props.open ? 'angle-up' : 'angle-down'">
              </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content" style="text-align: left">
          <b-collapse
            :open="false"
            class="card setting-topic"
            animation="slide"
            aria-id="contentIdForA11y3"
            v-for="(pond, index) in aeratorControlFeature"
            :key="index"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open"
              >
                <p class="card-header-title" style="margin: 0">
                  ตั้งค่า บ่อที่{{ pond.id }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    pack="fas"
                    :icon="props.open ? 'angle-up' : 'angle-down'"
                  >
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content">
              <div>
                <b-field :label="`Start aerator when DO less than` | t">
                  <b-numberinput
                    step="0.01"
                    :controls="false"
                    min="0"
                    max="20"
                    v-model="items[parseInt(pond.id) - 1].PondDOStart"
                  >
                  </b-numberinput>
                </b-field>
                <b-field :label="`Stop aerator when DO more than` | t">
                  <b-numberinput
                    step="0.01"
                    :controls="false"
                    min="0"
                    max="20"
                    v-model="items[parseInt(pond.id) - 1].PondDOStop"
                  >
                  </b-numberinput>
                </b-field>
              </div>
            </div>
          </b-collapse>
        </div>
        <footer class="card-footer">
          <a @click="onApplyAeratorSetting" class="card-footer-item">{{
            $t("Apply")
          }}</a>
        </footer>
      </b-collapse>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <!-- <b-button :label="$t('Refresh')" type="is-primary" @click="getNewData" /> -->
      <b-button :label="$t('Close')" type="is-primary" @click="onClose" />
    </footer>
    <b-modal
      v-model="isShowCalibrateDO"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <CalibrateDO :thingID="thingID" />
    </b-modal>

    <b-modal
      v-model="isShowCalibratePH"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <CalibratePH :thingID="thingID" />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { mapGetters } from "vuex";
import CalibrateDO from "@/components/devices/SOOB_V1/Calibrate/CalibrateDO.vue";
import CalibratePH from "@/components/devices/SOOB_V1/Calibrate/CalibratePH.vue";
import { debounce } from "debounce";

import Vue from "vue";

//Config constant
// const MQTT_TOPIC = "SOOB";

export default {
  name: "OverallSetting",
  props: {
    thingID: String,
    MQTT_TOPIC: String,
    features: Object,
  },
  components: {
    CalibrateDO,
    CalibratePH,
  },
  data() {
    return {
      device: null,
      localName: "",
      editMode: false,
      time: null,
      aeratorMode: null,
      isShowCalibrateDO: false,
      isShowCalibratePH: false,
      setting: {
        PondFlush: null,
        LocTAWarn: null,
        LocHumidWarn: null,
      },
      items: [
        {
          id: 1,
          name: "บ่อ 1",
          status: null,
          PondSoob: null,
          PondDOWarn: 0,
          PondPhWarn: 0,
          PondTWWarn: 0,
          PondECWarn: 0,
          PondDOStart: 0,
          PondDOStop: 0,
        },
        {
          id: 2,
          name: "บ่อ 2",
          status: null,
          PondSoob: null,
          PondDOWarn: 0,
          PondPhWarn: 0,
          PondTWWarn: 0,
          PondECWarn: 0,
          PondDOStart: 0,
          PondDOStop: 0,
        },
        {
          id: 3,
          name: "บ่อ 3",
          status: null,
          PondSoob: null,
          PondDOWarn: 0,
          PondPhWarn: 0,
          PondTWWarn: 0,
          PondECWarn: 0,
          PondDOStart: 0,
          PondDOStop: 0,
        },
        {
          id: 4,
          name: "บ่อ 4",
          status: null,
          PondSoob: null,
          PondDOWarn: 0,
          PondPhWarn: 0,
          PondTWWarn: 0,
          PondECWarn: 0,
          PondDOStart: 0,
          PondDOStop: 0,
        },
      ],
      updateAccepted: null,
      getAccepted: null,
      deviceConfig: null,
      calibratingPond: 0,
    };
  },
  computed: {
    ...mapGetters({
      messageCounter: "mq/messageCounter",
    }),
    calibrateFeature() {
      const calDO =
        this.features.calibrate.findIndex((cal) => cal.field === "DO") >= 0
          ? true
          : false;
      const calPH =
        this.features.calibrate.findIndex((cal) => cal.field === "pH") >= 0
          ? true
          : false;
      return {
        DO: calDO,
        pH: calPH,
      };
    },
    flushIntervalFeature() {
      return this.features.FlushInterval ? true : false;
    },
    pumpIntervalFeature() {
      return this.features.PumpInterval || [];
    },
    pondControlFeature() {
      return this.features.PondEnable ? true : false;
    },
    warningFeature() {
      return this.features.WarningSetting;
    },
    aeratorControlFeature() {
      return this.features.AeratorModeSetting;
    },
  },

  async mounted() {
    this.device = await this.$service.getThing(this.thingID);
    this.localName = this.device.name;

    this.deviceConfig = await this.$service.getDeviceConfiguration();

    //Subscribe
    await this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/${this.MQTT_TOPIC}/update/accepted`
    );
    await this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/${this.MQTT_TOPIC}/get/accepted`
    );
    await this.$store.dispatch("mq/MqttSubscribe", `${this.thingID}/PRECAL`);

    //Publish
    await this.$store.dispatch("mq/MqttPublish", {
      topic: `${this.thingID}/shadow/${this.MQTT_TOPIC}/get`,
      payload: "0",
    });
  },

  watch: {
    messageCounter: {
      handler: async function() {
        this.updateAccepted = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/shadow/${this.MQTT_TOPIC}/update/accepted`
        );
        this.getAccepted = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/shadow/${this.MQTT_TOPIC}/get/accepted`
        );
        this.calibratingPond = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/PRECAL`
        );
      },
    },

    "getAccepted.reported.PondEn00": {
      handler: function(value) {
        console.log("updateAccepted.reported.PondEn00: ", value);
        this.items[0].status = value;
      },
    },
    "getAccepted.reported.PondEn01": {
      handler: function(value) {
        console.log("updateAccepted.reported.PondEn01: ", value);
        this.items[1].status = value;
      },
    },
    "getAccepted.reported.PondEn02": {
      handler: function(value) {
        console.log("updateAccepted.reported.PondEn02: ", value);
        this.items[2].status = value;
      },
    },
    "getAccepted.reported.PondEn03": {
      handler: function(value) {
        console.log("updateAccepted.reported.PondEn03: ", value);
        this.items[3].status = value;
      },
    },
    "getAccepted.reported.PondFlush": {
      handler: function(value) {
        console.log("getAccepted.reported.PondFlush: ", value);
        this.setting.PondFlush = value;
      },
    },
    "getAccepted.reported.PondSoob00": {
      handler: function(value) {
        console.log("getAccepted.reported.PondSoob00: ", value);
        this.items[0].PondSoob = value;
      },
    },
    "getAccepted.reported.PondSoob01": {
      handler: function(value) {
        console.log("getAccepted.reported.PondSoob01: ", value);
        this.items[1].PondSoob = value;
      },
    },
    "getAccepted.reported.PondSoob02": {
      handler: function(value) {
        console.log("getAccepted.reported.PondSoob02: ", value);
        this.items[2].PondSoob = value;
      },
    },
    "getAccepted.reported.PondSoob03": {
      handler: function(value) {
        console.log("getAccepted.reported.PondSoob03: ", value);
        this.items[3].PondSoob = value;
      },
    },

    "getAccepted.reported.LocTAWarn": {
      handler: function(value) {
        console.log("getAccepted.reported.LocTAWarn: ", value);
        this.setting.LocTAWarn = value;
      },
    },
    "getAccepted.reported.LocHumidWarn": {
      handler: function(value) {
        console.log("getAccepted.reported.LocHumidWarn: ", value);
        this.setting.LocHumidWarn = value;
      },
    },

    "getAccepted.reported.PondDOWarn00": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOWarn00: ", value);
        this.items[0].PondDOWarn = value;
      },
    },
    "getAccepted.reported.PondDOWarn01": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOWarn01: ", value);
        this.items[1].PondDOWarn = value;
      },
    },
    "getAccepted.reported.PondDOWarn02": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOWarn02: ", value);
        this.items[2].PondDOWarn = value;
      },
    },
    "getAccepted.reported.PondDOWarn03": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOWarn03: ", value);
        this.items[3].PondDOWarn = value;
      },
    },

    "getAccepted.reported.PondPhWarn00": {
      handler: function(value) {
        console.log("getAccepted.reported.PondPhWarn00: ", value);
        this.items[0].PondPhWarn = value;
      },
    },
    "getAccepted.reported.PondPhWarn01": {
      handler: function(value) {
        console.log("getAccepted.reported.PondPhWarn01: ", value);
        this.items[1].PondPhWarn = value;
      },
    },
    "getAccepted.reported.PondPhWarn02": {
      handler: function(value) {
        console.log("getAccepted.reported.PondPhWarn02: ", value);
        this.items[2].PondPhWarn = value;
      },
    },
    "getAccepted.reported.PondPhWarn03": {
      handler: function(value) {
        console.log("getAccepted.reported.PondPhWarn03: ", value);
        this.items[3].PondPhWarn = value;
      },
    },

    "getAccepted.reported.PondTWWarn00": {
      handler: function(value) {
        console.log("getAccepted.reported.PondTWWarn00: ", value);
        this.items[0].PondTWWarn = value;
      },
    },
    "getAccepted.reported.PondTWWarn01": {
      handler: function(value) {
        console.log("getAccepted.reported.PondTWWarn01: ", value);
        this.items[1].PondTWWarn = value;
      },
    },
    "getAccepted.reported.PondTWWarn02": {
      handler: function(value) {
        console.log("getAccepted.reported.PondTWWarn02: ", value);
        this.items[2].PondTWWarn = value;
      },
    },
    "getAccepted.reported.PondTWWarn03": {
      handler: function(value) {
        console.log("getAccepted.reported.PondTWWarn03: ", value);
        this.items[3].PondTWWarn = value;
      },
    },

    "getAccepted.reported.PondECWarn00": {
      handler: function(value) {
        console.log("getAccepted.reported.PondECWarn00: ", value);
        this.items[0].PondECWarn = value;
      },
    },
    "getAccepted.reported.PondECWarn01": {
      handler: function(value) {
        console.log("getAccepted.reported.PondECWarn01: ", value);
        this.items[1].PondECWarn = value;
      },
    },
    "getAccepted.reported.PondECWarn02": {
      handler: function(value) {
        console.log("getAccepted.reported.PondECWarn02: ", value);
        this.items[2].PondECWarn = value;
      },
    },
    "getAccepted.reported.PondECWarn03": {
      handler: function(value) {
        console.log("getAccepted.reported.PondECWarn03: ", value);
        this.items[3].PondECWarn = value;
      },
    },

    "getAccepted.reported.PondDOStart00": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStart00: ", value);
        this.items[0].PondDOStart = value;
      },
    },
    "getAccepted.reported.PondDOStart01": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStart01: ", value);
        this.items[1].PondDOStart = value;
      },
    },
    "getAccepted.reported.PondDOStart02": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStart02: ", value);
        this.items[2].PondDOStart = value;
      },
    },
    "getAccepted.reported.PondDOStart03": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStart03: ", value);
        this.items[3].PondDOStart = value;
      },
    },

    "getAccepted.reported.PondDOStop00": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStop00: ", value);
        this.items[0].PondDOStop = value;
      },
    },
    "getAccepted.reported.PondDOStop01": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStop01: ", value);
        this.items[1].PondDOStop = value;
      },
    },
    "getAccepted.reported.PondDOStop02": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStop02: ", value);
        this.items[2].PondDOStop = value;
      },
    },
    "getAccepted.reported.PondDOStop03": {
      handler: function(value) {
        console.log("getAccepted.reported.PondDOStop03: ", value);
        this.items[3].PondDOStop = value;
      },
    },
  },

  methods: {
    async onClose() {
      this.$parent.close();
    },
    onOpenCalibrateDO() {
      console.log("onOpenCalibrateDO");
      this.isShowCalibrateDO = true;
    },
    onOpenCalibratePH() {
      console.log("onOpenCalibratePH");
      this.isShowCalibratePH = true;
    },
    async onApplyPondEn() {
      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.MQTT_TOPIC}/get/accepted`
      );
      getMsg.desired.PondEn00 = this.items[0].status;
      getMsg.desired.PondEn01 = this.items[1].status;
      getMsg.desired.PondEn02 = this.items[2].status;
      getMsg.desired.PondEn03 = this.items[3].status;

      console.log("onApplyPondEn", getMsg.desired);
      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.MQTT_TOPIC}/update`,
        payload: JSON.stringify(getMsg),
      });
      this.snackbar("การตั้งค่าถูกนำไปใช้");
    },
    async onApplyPondFlush() {
      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.MQTT_TOPIC}/get/accepted`
      );
      getMsg.desired.PondFlush = this.setting.PondFlush;

      console.log("onApplyPondEn", getMsg.desired);
      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.MQTT_TOPIC}/update`,
        payload: JSON.stringify(getMsg),
      });
      this.snackbar("การตั้งค่าถูกนำไปใช้");
    },
    async onApplyWarn() {
      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.MQTT_TOPIC}/get/accepted`
      );

      //PondDOWarn
      getMsg.desired.PondDOWarn00 = this.items[0].PondDOWarn;
      getMsg.desired.PondDOWarn01 = this.items[1].PondDOWarn;
      getMsg.desired.PondDOWarn02 = this.items[2].PondDOWarn;
      getMsg.desired.PondDOWarn03 = this.items[3].PondDOWarn;

      //PondPhWarn
      getMsg.desired.PondPhWarn00 = this.items[0].PondPhWarn;
      getMsg.desired.PondPhWarn01 = this.items[1].PondPhWarn;
      getMsg.desired.PondPhWarn02 = this.items[2].PondPhWarn;
      getMsg.desired.PondPhWarn03 = this.items[3].PondPhWarn;

      //PondTWWarn
      getMsg.desired.PondTWWarn00 = this.items[0].PondTWWarn;
      getMsg.desired.PondTWWarn01 = this.items[1].PondTWWarn;
      getMsg.desired.PondTWWarn02 = this.items[2].PondTWWarn;
      getMsg.desired.PondTWWarn03 = this.items[3].PondTWWarn;

      //LocTAWarn
      getMsg.desired.LocTAWarn = this.setting.LocTAWarn;

      //LocHumidWarn
      getMsg.desired.LocHumidWarn = this.setting.LocHumidWarn;

      //PondECWarn00
      getMsg.desired.PondECWarn00 = this.items[0].PondECWarn;
      getMsg.desired.PondECWarn01 = this.items[1].PondECWarn;
      getMsg.desired.PondECWarn02 = this.items[2].PondECWarn;
      getMsg.desired.PondECWarn03 = this.items[3].PondECWarn;

      console.log("onApplyWarn", getMsg.desired);
      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.MQTT_TOPIC}/update`,
        payload: JSON.stringify(getMsg),
      });
      this.snackbar("การตั้งค่าถูกนำไปใช้");
    },
    async onApplyPondSoob() {
      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.MQTT_TOPIC}/get/accepted`
      );
      getMsg.desired.PondSoob00 = this.items[0].PondSoob;
      getMsg.desired.PondSoob01 = this.items[1].PondSoob;
      getMsg.desired.PondSoob02 = this.items[2].PondSoob;
      getMsg.desired.PondSoob03 = this.items[3].PondSoob;

      console.log("onApplyPondSoob", getMsg.desired);
      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.MQTT_TOPIC}/update`,
        payload: JSON.stringify(getMsg),
      });
      this.snackbar("การตั้งค่าถูกนำไปใช้");
    },
    async onApplyAeratorSetting() {
      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.MQTT_TOPIC}/get/accepted`
      );

      getMsg.desired.PondDOStart00 = this.items[0].PondDOStart;
      getMsg.desired.PondDOStart01 = this.items[1].PondDOStart;
      getMsg.desired.PondDOStart02 = this.items[2].PondDOStart;
      getMsg.desired.PondDOStart03 = this.items[3].PondDOStart;

      getMsg.desired.PondDOStop00 = this.items[0].PondDOStop;
      getMsg.desired.PondDOStop01 = this.items[1].PondDOStop;
      getMsg.desired.PondDOStop02 = this.items[2].PondDOStop;
      getMsg.desired.PondDOStop03 = this.items[3].PondDOStop;

      console.log("onApplyAeratorSetting", getMsg.desired);
      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.MQTT_TOPIC}/update`,
        payload: JSON.stringify(getMsg),
      });
      this.snackbar("การตั้งค่าถูกนำไปใช้");
    },
    getNewData: debounce(async function() {
      if (this.updateAccepted?.code === 200) {
        console.log("Get new value");
        await this.$store.dispatch("mq/MqttPublish", {
          topic: `${this.thingID}/shadow/${this.MQTT_TOPIC}/get`,
          payload: "0",
        });
      }
    }, 500),
    onCopyWarningValue(pondId) {
      console.log("duplicate value from pond ", pondId);
      this.items.forEach((pond, index) => {
        this.items[index].PondDOWarn = this.items[pondId - 1].PondDOWarn;
        this.items[index].PondPhWarn = this.items[pondId - 1].PondPhWarn;
        this.items[index].PondTWWarn = this.items[pondId - 1].PondTWWarn;
        this.items[index].PondECWarn = this.items[pondId - 1].PondECWarn;
      });
      this.snackbar("คัดลอกค่าไปยังทุกบ่อสำเร็จ");
    },
    snackbar(message) {
      this.$buefy.snackbar.open({
        duration: 3000,
        message: message,
        type: "is-white",
        actionText: null,
        position: "is-bottom-left",
      });
    },
    async onSaveNewName() {
      const name = this.localName.trim();
      if (!name) {
        return;
      }
      console.log("new name: ", name);

      try {
        await this.$service.updateThingName(this.device.thingID, name);
        this.fetchUser();
        this.$emit("changed-name");
      } catch (error) {
        console.error(error);
      }
      this.editMode = false;
    },
    async fetchUser() {
      const user = this.$store.getters["auth/user"];
      const { username, attributes } = user;
      const { email } = attributes;
      const result = await this.$store.dispatch("user/fetchUser", {
        username,
        name: email,
      });
      console.log("fetchUser", result.things.items);

      result.things.items = await Promise.all(
        result.things.items.map(async (thing) => {
          const response = await this.$service.getThing(thing.thingID);
          return {
            ...thing,
            name: response.name,
          };
        })
      );

      await this.$store.commit("user/setUser", result);
    },
    onEditMode() {
      this.editMode = true;
      const element = this.$refs.deviceName;
      Vue.nextTick(() => {
        element.focus();
      });
    },
    async onSelectCalibratingPond(pondId) {
      console.log("onSelectCalibratingPond", pondId);
      await this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/PRECAL`,
        payload: `${pondId}`,
        retain: true,
      });
    },
  },
};
</script>

<style scoped>
a {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Old version of Firefox */
  -ms-user-select: none; /* Internet Explorer or Edge */
  user-select: none; /* All modern browsers */
}

.setting-topic {
  margin: 10px 0;
}
.description {
  text-align: left;
  margin-bottom: 10px;
}
>>> .card {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 2%),
    0 0px 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
}
</style>
