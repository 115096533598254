<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t("Add Sensor") }}</p>
        </header>
        <section class="modal-card-body">
            <b-field label="Device ID">
                <b-input
                    :value="thingID"
                    placeholder="thingID"
                    required
                    disabled
                >
                </b-input>
            </b-field>

            <b-field label="Role">
                <b-input :value="role" placeholder="Role" required disabled>
                </b-input>
            </b-field>

            <p
                v-if="role === 'Maintainer'"
                class="has-text-danger"
                style="text-align:left"
            >
                {{
                    $t(
                        "You can access the device after the owner accept your access request."
                    )
                }}
            </p>
            <!-- <b-checkbox>Remember me</b-checkbox> -->
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button label="Cancel" @click="$parent.close()" />
            <b-button label="Confirm" type="is-primary" @click="onPairDevice" />
        </footer>
    </div>
</template>

<script>
export default {
    name: "AddSensorQRCodeForm",
    props: ["thingID", "role", "ownerID"],
    methods: {
        async onPairDevice() {
            console.log("onPairDevice");
            try {
                switch (this.role) {
                    case "Maintainer":
                        {
                            const user = this.$store.getters["auth/user"];
                            let username = "unknown";
                            if (user.attributes) {
                                if (user.attributes.email) {
                                    username = user.attributes.email;
                                }
                            }

                            await this.$store.dispatch(
                                "addDevice/requestAccess",
                                {
                                    thingID: this.thingID,
                                    userID: user.username,
                                    username,
                                    role: this.role,
                                    ownerID: this.ownerID,
                                }
                            );
                            this.$parent.close();
                            this.$router.go(-2);
                        }
                        break;
                    case "Owner":
                        {
                            const user = this.$store.getters["auth/user"];
                            await this.$store.dispatch("addDevice/pair", {
                                thingID: this.thingID,
                                userID: user.username,
                                role: this.role,
                            });
                            await this.fetchUser();
                            this.$parent.close();
                            this.$router.go(-2);
                        }
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchUser() {
            const user = this.$store.getters["auth/user"];
            const { username, attributes } = user;
            const { email } = attributes;
            const result = await this.$store.dispatch("user/fetchUser", {
                username,
                name: email,
            });
            await this.$store.commit("user/setUser", result);
        },
    },
};
</script>
