<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t("Calibrate DO") }}</p>
        </header>
        <section class="modal-card-body" style="text-align: start">
            <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode"
            >
                <b-step-item size="is-small" step="1">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Prepare
                    </div>
                    Before calibrating the probe, disconnect the temperature
                    probe from the transmitter. Temperature compensation is not
                    part of calibration. Disconnecting the temperature probe
                    makes the calibration process easier.
                </b-step-item>

                <b-step-item size="is-small" step="2">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Calibrate Low
                    </div>

                    <div>
                        This step is only needed if high accuracy measurements
                        are required for dissolved oxygen readings less than 1.5
                        mg/L. Place the probe in the zero dissolved oxygen
                        calibration solution.
                    </div>

                    <div class="has-text-centered mt-6">
                        <b-button
                            type="is-danger"
                            :loading="calibrateLowLoading"
                            label="Calibrate Low"
                            @click="onCalibrateLow"
                        />
                    </div>
                </b-step-item>

                <b-step-item size="is-small" step="3">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Calibrate High
                    </div>

                    <div>
                        With the dissolved oxygen probe sitting out in the air,
                        and the temp sensor disconnected, let the readings
                        stabilize. This usually takes no more than a few
                        minutes; once the readings have stabilized, press and
                        hold the “High”
                    </div>

                    <div class="has-text-centered mt-6">
                        <b-button
                            type="is-danger"
                            :loading="calibrateHighLoading"
                            label="Calibrate High"
                            @click="onCalibrateHigh"
                        />
                    </div>
                </b-step-item>

                <b-step-item size="is-small" step="4">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Finish
                    </div>
                    Calibration completed.
                </b-step-item>

                <template #navigation="{previous, next}">
                    <b-button
                        class="mr-1"
                        outlined
                        icon-left="arrow-left"
                        :disabled="previous.disabled"
                        @click.prevent="previous.action"
                    >
                        Previous
                    </b-button>
                    <b-button
                        outlined
                        icon-right="arrow-right"
                        :disabled="next.disabled"
                        @click.prevent="next.action"
                    >
                        Next
                    </b-button>
                </template>
            </b-steps>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button :label="`Close` | t" type="is-primary" @click="onClose" />
        </footer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "CalibrateDO",
    props: {
        thingID: String,
    },
    data() {
        return {
            activeStep: 0,

            showSocial: false,
            isAnimated: true,
            isRounded: true,
            isStepsClickable: false,

            hasNavigation: true,
            customNavigation: false,
            isProfileSuccess: false,

            prevIcon: "chevron-left",
            nextIcon: "chevron-right",
            labelPosition: "bottom",
            mobileMode: null,

            calibrateLowLoading: false,
            calibrateHighLoading: false,
            CALDOLowGetAcceptedJson: null,
            CALDOLowUpdateAccepted: null,
            CALDOHighGetAcceptedJson: null,
            CALDOHighUpdateAccepted: null,
        };
    },
    computed: {
        ...mapGetters({
            messageCounter: "mq/messageCounter",
        }),
    },
    watch: {
        messageCounter() {
            // low
            this.CALDOLowGetAcceptedJson = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALDOLow/get/accepted/json`);
            this.CALDOLowUpdateAccepted = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALDOLow/update/accepted`);

            // high
            this.CALDOHighGetAcceptedJson = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALDOHigh/get/accepted/json`);
            this.CALDOHighUpdateAccepted = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALDOHigh/update/accepted`);
        },
        CALDOLowGetAcceptedJson: {
            handler: function(value) {
                console.log("CALDOLowGetAcceptedJson update!", value);
                if (value) {
                    if (value.desired == 0) {
                        this.calibrateLowLoading = false;
                    }
                }
            },
        },
        CALDOLowUpdateAccepted: {
            handler: function(value) {
                console.log("CALDOLowUpdateAccepted update!", value);
                this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/CALDOLow/get/json`,
                    payload: "0",
                });
            },
        },
        CALDOHighGetAcceptedJson: {
            handler: function(value) {
                console.log("CALDOHighGetAcceptedJson update!", value);
                if (value) {
                    if (value.desired == 0) {
                        this.calibrateHighLoading = false;
                    }
                }
            },
        },
        CALDOHighUpdateAccepted: {
            handler: function(value) {
                console.log("CALDOHighUpdateAccepted update!", value);
                this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/CALDOHigh/get/json`,
                    payload: "0",
                });
            },
        },
    },
    mounted() {
        // subscribe low
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALDOLow/update/delta/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALDOLow/get/accepted/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALDOLow/update/accepted`
        );

        // subscribe high
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALDOHigh/update/delta/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALDOHigh/get/accepted/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALDOHigh/update/accepted`
        );

        // publish
        this.$store.dispatch("mq/MqttPublish", {
            topic: `${this.thingID}/shadow/CALDOLow/get/json`,
            payload: "0",
        });
        this.$store.dispatch("mq/MqttPublish", {
            topic: `${this.thingID}/shadow/CALDOHigh/get/json`,
            payload: "0",
        });
    },
    methods: {
        onCalibrateLow() {
            this.calibrateLowLoading = true;

            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/CALDOLow/update`,
                payload: JSON.stringify({
                    desired: 1,
                    delta: 1,
                }),
            });
        },
        onCalibrateHigh() {
            this.calibrateHighLoading = true;

            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/CALDOHigh/update`,
                payload: JSON.stringify({
                    desired: 1,
                }),
            });
        },
        async onClose() {
            this.$parent.close();
        },
    },
};
</script>
