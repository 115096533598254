<template>
    <div class="box is-flex device-card">
        <div class="is-flex is-flex-grow-1 is-justify-content-space-between">
            <p class="is-size-5">
                {{ $t("Alert") }}
            </p>
        </div>
        <div class="detail">
            <div class="group">
                <p class="is-size-6">
                    <b>{{ $t("Thing ID:") }}</b> {{ rule.thingID }}
                </p>
                <p class="is-size-6">
                    <b>{{ $t("Field:") }}</b> {{ rule.field }}
                </p>
            </div>
            <div class="group">
                <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <p class="is-size-6">
                                <b>{{ $t("Method") }}:</b>
                            </p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <p class="is-size-6">
                                {{ rule.method | t }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <p class="is-size-6">
                                <b>{{ $t("Upper bound") }}:</b>
                                {{ rule.upperBound }}
                            </p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <p class="is-size-6">
                                <b>{{ $t("Lower bound") }}:</b>
                                {{ rule.lowerBound }}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- <div class="level is-mobile">
                    <div class="level-left">
                        <div class="level-item">
                            <p class="is-size-6">
                                <b>{{ $t("Sensitivity:") }}</b>
                                {{ rule.sensitivity }}
                            </p>
                        </div>
                    </div>
                    <div class="level-right">
                        <div class="level-item">
                            <p class="is-size-6">
                                <b>{{ $t("Threshold:") }}</b>
                                {{ rule.threshold }}
                            </p>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="group level is-mobile">
                <div class="level-left">
                    <p class="is-size-6">
                        <b>{{ $t("Alert to:") }}</b>
                    </p>
                </div>
                <div class="level-right">
                    <b-taglist>
                        <b-tag
                            v-for="(item, index) in rule.alerts.items"
                            :key="index"
                            type="is-info"
                            >{{ item.alert.name }}
                        </b-tag>
                    </b-taglist>
                </div>
            </div>

            <div class="group level is-mobile">
                <div class="level-left">
                    <p class="level-item is-size-6">
                        <b>{{ $t("Enable") }}</b>
                    </p>
                </div>
                <div class="level-right">
                    <b-field class="level-item">
                        <b-switch v-model="rule.enable"></b-switch>
                    </b-field>
                </div>
            </div>

            <div class="level is-mobile">
                <div class="level-left">
                </div>
                <div class="level-right">
                    <b-button v-if="!rule.enable" @click="onRemove">{{
                        $t("Remove")
                    }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RuleCard",
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    watch: {
        "rule.enable": {
            immediate: false,
            handler: function(value) {
                this.$store.dispatch("anomalyDetectorTask/enableRule", {
                    id: this.rule.id,
                    enable: value,
                });
            },
        },
    },
    methods: {
        onRemove() {
            console.log("onRemove");
            this.$store.dispatch("anomalyDetectorTask/removeRule", {
                id: this.rule.id
            })
        },
    },
};
</script>

<style scoped>
.device-card {
    display: block !important;
    /* min-height: 100px; */
}
.group {
    border: 1px solid rgb(219, 219, 219);
    padding: 5px;
    border-radius: 10px;
    margin: 5px 0;
}
</style>
