<template>
    <div class="box p-2 is-flex device-card">
        <div
            class="is-flex is-flex-grow-1 is-justify-content-space-between is-align-items-center"
        >
            <div class="is-flex">
                <b-icon
                    pack="fab"
                    style="color:#00B900;"
                    icon="line"
                    size="is-large"
                />
                <p class="is-size-4 pt-2">
                    {{ notification.name }}
                </p>
            </div>
            <b-button
                type="is-ghost"
                size="is-small"
                :icon-left="'ellipsis-v'"
                @click="onOpenOption"
            >
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotifyCard",
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onOpenOption() {
            console.log("onOpenOption");
        },
    },
};
</script>

<style scoped>
.device-card {
    display: block !important;
    /* min-height: 100px; */
}
</style>
