<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t("Calibrate pH") }}</p>
    </header>
    <section class="modal-card-body" style="text-align: start">
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <b-step-item size="is-small" step="1">
          <div class="is-size-5 has-text-weight-semibold has-text-centered">
            Prepare
          </div>
          Before calibrating the probe, disconnect the temperature probe from
          the transmitter. Temperature compensation is not part of calibration.
          Disconnecting the temperature probe makes the calibration process
          easier.
        </b-step-item>

        <b-step-item size="is-small" step="2">
          <div class="is-size-5 has-text-weight-semibold has-text-centered">
            Calibrate pH7
          </div>

          <div>
            Calibrating to pH 7.00 will reset the stored calibration. If two, or
            three point calibration has been done in the past, it must now be
            redone.
          </div>

          <div class="mt-3">You can skip this step.</div>

          <div class="has-text-centered mt-6">
            <b-button
              type="is-danger"
              :loading="calibratePHMidLoading"
              label="Calibrate pH7"
              @click="onCalibratePHMid"
            />
          </div>
        </b-step-item>

        <b-step-item size="is-small" step="3">
          <div class="is-size-5 has-text-weight-semibold has-text-centered">
            Calibrate pH4
          </div>

          <div></div>

          <div class="has-text-centered mt-6">
            <b-button
              type="is-danger"
              :loading="calibratePHLowLoading"
              label="Calibrate pH4"
              @click="onCalibratePHLow"
            />
          </div>
        </b-step-item>

        <b-step-item size="is-small" step="4">
          <div class="is-size-5 has-text-weight-semibold has-text-centered">
            Calibrate pH10
          </div>

          <div></div>

          <div class="has-text-centered mt-6">
            <b-button
              type="is-danger"
              :loading="calibratePHHighLoading"
              label="Calibrate pH10"
              @click="onCalibratePHHigh"
            />
          </div>
        </b-step-item>

        <b-step-item size="is-small" step="5">
          <div class="is-size-5 has-text-weight-semibold has-text-centered">
            Finish
          </div>
          Calibration completed.
        </b-step-item>

        <template #navigation="{ previous, next }">
          <b-button
            class="mr-1"
            outlined
            icon-left="arrow-left"
            :disabled="previous.disabled || activeStep === 4"
            @click.prevent="previous.action"
          >
            Previous
          </b-button>
          <b-button
            outlined
            icon-right="arrow-right"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
            Next
          </b-button>
        </template>
      </b-steps>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <b-button :label="`Close` | t" type="is-primary" @click="onClose" />
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const TOPIC = "CAL";

export default {
  name: "CalibratePH_SOOB",
  props: {
    thingID: String,
  },
  data() {
    return {
      activeStep: 0,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: null,

      updateAccepted: null,

      calibratePHLowLoading: false,
      calibratePHMidLoading: false,
      calibratePHHighLoading: false,

      CALPHLowGetAccepted: null,
      CALPHMidGetAccepted: null,
      CALPHHighGetAccepted: null,
    };
  },
  computed: {
    ...mapGetters({
      messageCounter: "mq/messageCounter",
    }),
  },
  mounted() {
    // subscribe
    this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/CAL/get/accepted`
    );
    this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/CAL/update/accepted`
    );

    // publish
    this.$store.dispatch("mq/MqttPublish", {
      topic: `${this.thingID}/shadow/CAL/get`,
      payload: "0",
    });
  },
  watch: {
    messageCounter: {
      handler: async function() {
        this.updateAccepted = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/shadow/${TOPIC}/update/accepted`
        );
        this.CALPHLowGetAccepted = await this.$store.getters[
          "mq/messageByTopic"
        ](`${this.thingID}/shadow/${TOPIC}/get/accepted`)?.reported?.pH.low;
        this.CALPHMidGetAccepted = await this.$store.getters[
          "mq/messageByTopic"
        ](`${this.thingID}/shadow/${TOPIC}/get/accepted`)?.reported?.pH.mid;
        this.CALPHHighGetAccepted = await this.$store.getters[
          "mq/messageByTopic"
        ](`${this.thingID}/shadow/${TOPIC}/get/accepted`)?.reported?.pH.high;
      },
    },
    "updateAccepted.timestamp": {
      handler: async function() {
        this.$store.dispatch("mq/MqttPublish", {
          topic: `${this.thingID}/shadow/CAL/get`,
          payload: "0",
        });
      },
    },
    CALPHLowGetAccepted: {
      handler: function(value) {
        if (value === 1) {
          this.calibratePHLowLoading = true;
          return;
        }
        this.calibratePHLowLoading = false;
      },
    },
    CALPHMidGetAccepted: {
      handler: function(value) {
        if (value === 1) {
          this.calibratePHMidLoading = true;
          return;
        }
        this.calibratePHMidLoading = false;
      },
    },
    CALPHHighGetAccepted: {
      handler: function(value) {
        if (value === 1) {
          this.calibratePHHighLoading = true;
          return;
        }
        this.calibratePHHighLoading = false;
      },
    },
    activeStep: {
      handler: async function(value) {
        if (value === 4) {
          await this.$store.dispatch("mq/MqttPublish", {
            topic: `${this.thingID}/PRECAL`,
            payload: `0`,
            retain: true,
          });
        }
      },
    },
  },
  methods: {
    onCalibratePHLow() {
      this.calibratePHLowLoading = true;

      let payload = this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${TOPIC}/get/accepted`
      );

      payload.desired.pH.low = 1;
      console.log("onCalibrateLow", payload);

      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${TOPIC}/update`,
        payload: JSON.stringify(payload),
      });
    },
    onCalibratePHMid() {
      this.calibratePHMidLoading = true;

      let payload = this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${TOPIC}/get/accepted`
      );

      payload.desired.pH.mid = 1;
      console.log("onCalibrateMid", payload);

      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${TOPIC}/update`,
        payload: JSON.stringify(payload),
      });
    },
    onCalibratePHHigh() {
      this.calibratePHHighLoading = true;

      let payload = this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${TOPIC}/get/accepted`
      );

      payload.desired.pH.high = 1;
      console.log("onCalibrateLow", payload);

      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${TOPIC}/update`,
        payload: JSON.stringify(payload),
      });
    },
    async onClose() {
      this.$parent.close();
    },
  },
};
</script>
