<template>
  <div style="position: relative">
    <CalibrationOverlay
      v-if="isCalibrating > 0"
      @cancel="onCancelCalibration"
    />
    <b-tabs expanded position="is-centered" v-model="activeTab">
      <b-tab-item v-for="item in items" :key="item.id" :label="item.name">
        <template #header>
          <span>
            {{ item.name }}
            <b-tag v-if="item.status === 1" type="is-success" rounded>
              ON
            </b-tag>
            <b-tag v-else type="is-danger" rounded> OFF </b-tag>
          </span>
        </template>
        <div
          class="relative columns m-0 p-0 is-multiline"
          style="min-height: 300px"
        >
          <!-- <DeviceFieldCard
            v-for="sensor in sensorList"
            :thingID="thingID"
            :id="item.id"
            :key="sensor.id"
            :title="sensor.name"
            :field="sensor.name"
            :unit="sensor.unit"
            class="column is-half-tablet"
          /> -->
          <DeviceFieldCardV2
            v-for="sensor in sensorList"
            :thingID="thingID"
            :id="item.id"
            :key="sensor.id"
            :title="sensor.name"
            :field="sensor.name"
            :unit="sensor.unit"
            class="column is-full"
            :desc="sensor.desc"
            :reported="getAccepted.reported"
          />
          <DeviceControlCardSOOB
            :thingID="thingID"
            :id="item.id"
            :mqttTopic="`JOOM`"
            class="column is-half-tablet"
          />
          <!-- <DeviceSettingsCard :thingID="thingID" class="column is-half-tablet" /> -->
        </div>
      </b-tab-item>

      <!-- <b-tab-item label="Music">
                Lorem <br />
                ipsum <br />
                dolor <br />
                sit <br />
                amet.
            </b-tab-item>

            <b-tab-item label="Books">
                What light is light, if Silvia be not seen? <br />
                What joy is joy, if Silvia be not by— <br />
                Unless it be to think that she is by <br />
                And feed upon the shadow of perfection? <br />
                Except I be by Silvia in the night, <br />
                There is no music in the nightingale.
            </b-tab-item>

            <b-tab-item label="Videos">
                Nunc nec velit nec libero vestibulum eleifend. Curabitur
                pulvinar congue luctus. Nullam hendrerit iaculis augue vitae
                ornare. Maecenas vehicula pulvinar tellus, id sodales felis
                lobortis eget.
            </b-tab-item> -->
    </b-tabs>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { debounce } from "debounce";
import { mapGetters } from "vuex";

import DeviceFieldCard from "@/components/DeviceFieldCard.vue";
import DeviceFieldCardV2 from "@/components/v2/DeviceFieldCardV2.vue";
// import DeviceSettingsCard from "@/components/DeviceSettingsCard.vue";
import DeviceControlCardSOOB from "@/components/DeviceControlCardSOOB.vue";

//CONFIG CONSTANT
const TOPIC = "JOOM";

export default {
  name: "JOOM_V1",
  components: {
    // DeviceFieldCard,
    DeviceFieldCardV2,
    // DeviceSettingsCard,
    DeviceControlCardSOOB,
    CalibrationOverlay: () => import("@/components/v2/CalibrationOverlay.vue"),
  },
  props: {
    config: {
      type: Object,
    },
  },
  data() {
    return {
      activeTab: 0,
      items: [
        {
          id: 1,
          name: "บ่อ 1",
          status: null,
        },
        {
          id: 2,
          name: "บ่อ 2",
          status: null,
        },
        {
          id: 3,
          name: "บ่อ 3",
          status: null,
        },
        {
          id: 4,
          name: "บ่อ 4",
          status: null,
        },
      ],
      updateAccepted: null,
      getAccepted: null,
      isCalibrating: 0,
    };
  },

  computed: {
    ...mapGetters({
      messageCounter: "mq/messageCounter",
    }),
    thingID() {
      return this.$route.params["thingid"];
    },
    sensorList() {
      return this.config.field || [];
    },
  },

  async mounted() {
    //Subscribe
    await this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/${TOPIC}/update/accepted`
    );
    await this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/${TOPIC}/get/accepted`
    );
    await this.$store.dispatch("mq/MqttSubscribe", `${this.thingID}/PRECAL`);

    //Publish
    await this.$store.dispatch("mq/MqttPublish", {
      topic: `${this.thingID}/shadow/${TOPIC}/get`,
      payload: "0",
    });
  },

  watch: {
    messageCounter: {
      handler: async function() {
        this.updateAccepted = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/shadow/${TOPIC}/update/accepted`
        );
        this.getAccepted = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/shadow/${TOPIC}/get/accepted`
        );
        this.isCalibrating = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/PRECAL`
        );
      },
    },
    "updateAccepted.timestamp": {
      handler: async function() {
        console.log("updateAccepted value: ", this.updateAccepted);
        this.getNewData();
      },
    },
    getAccepted: {
      handler: function() {
        // console.log("getAccepted", this.getAccepted);
        if (this.getAccepted.reported) {
          const { reported } = this.getAccepted;
          console.log("reported: ", reported);
          this.items[0].status = reported.PondEn00;
          this.items[1].status = reported.PondEn01;
          this.items[2].status = reported.PondEn02;
          this.items[3].status = reported.PondEn03;
        }
      },
      deep: true,
    },
  },
  methods: {
    getNewData: debounce(async function() {
      if (this.updateAccepted?.code === 200) {
        console.log("Get new value");
        await this.$store.dispatch("mq/MqttPublish", {
          topic: `${this.thingID}/shadow/${TOPIC}/get`,
          payload: "0",
        });
      }
    }, 500),
    async onCancelCalibration() {
      // TODO Cancel calibrating
      console.log("onCancelCalibration");
      await this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/PRECAL`,
        payload: "0",
        retain: true,
      });
    },
  },
};
</script>

<style scoped>
.overlay {
  position: absolute;
  /* color: white; */
  background-color: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 10rem 1rem;
  justify-items: center;
}

.overlay > h1 {
  font-size: 3rem;
}

@media screen and (min-width: 600px) {
  .pond-status {
    border-radius: 2em;
    padding: 0.25em 0.5em;
  }
  .status-off {
    border: 1px solid #f14668;
  }
  .status-on {
    border: 1px solid #48c774;
  }
}
</style>
