












































































import Vue from "vue";
import { mapGetters } from "vuex";
import SystemsMessageInfo from "@/components/SystemsMessageInfo.vue";
import SystemsMessageAlert from "@/components/SystemsMessageAlert.vue";
import Auth from "@aws-amplify/auth";

const messageTypeMaping = {
  INFO: "SystemsMessageInfo",
  ALERT: "SystemsMessageAlert",
};

export default Vue.extend({
  name: "TopBar",
  components: {
    SystemsMessageInfo,
    SystemsMessageAlert,
  },
  data() {
    return {
      isFirstTime: true,
      menus: [
        {
          text: "test",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      messages: "systemsMessage/messages",
    }),
    messagesWithType: function(): Array<any> {
      // return [];
      return this.messages.map((i) => {
        return {
          ...i,
          type: messageTypeMaping[i.type],
        };
      });
    },
  },
  methods: {
    onSignout() {
      this.$emit("on-signout");
    },
    onShowMessage() {
      console.log("onShowMessage");
      if (this.isFirstTime) {
        this.isFirstTime = false;
        const user = this.$store.getters["auth/user"];
        this.$store.dispatch("systemsMessage/get", {
          userID: user.username,
        });
      }
    },
    async onHome() {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        this.$router.push({
          name: "Profile",
          params: {
            username: user.username,
          },
        });
      }
    },
    onAddDevice() {
      console.log("onAddDevice");
      this.$router.push({
        name: "ProfileAddSensorInstruction",
      });
    },
    onNotification() {
      this.$router.push({
        name: "ProfileNotifications",
      });
    },
  },
});
