<template>
  <div
    class="weather-forecast tw-w-full tw-h-full tw-bg-white tw-rounded-md p-4"
  >
    <div class="tw-flex-col">
      <!-- HEADER -->
      <div class="tw-flex tw-flex-row tw-justify-between">
        <div class="header tw-flex tw-flex-row tw-gap-1 md:tw-gap-4 tw-pb-2">
          <p class="tw-text-xl tw-text-primary">
            {{ $t("Weather Forecast") }}
          </p>
          <p class="tw-text-lg tw-text-gray-500">
            {{ displayWeatherLocation }}
          </p>
        </div>
        <b-button
          @click="onOpenWeatherLocationDialog"
          type="is-primary"
          size="is-small"
          icon-right="cog"
        ></b-button>
      </div>
      <!-- CONTENT -->
      <div v-if="!loading" class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-4">
        <div
          class="tw-bg-gray-500 tw-flex-none tw-py-4 tw-px-2 tw-flex-row weather-forecast-content tw-rounded-2xl tw-w-full sm:tw-w-72 lg:tw-w-80"
        >
          <div class="tw-flex tw-flex-col">
            <div class="left-box tw-flex tw-flex-row">
              <img
                v-if="current.weather"
                :src="
                  `http://openweathermap.org/img/wn/${current.weather[0].icon}@2x.png`
                "
                alt="weather icon"
                class="tw-flex-none"
              />
              <div
                class="tw-flex-auto tw-flex tw-flex-col tw-gap-2 tw-text-center tw-text-white"
              >
                <p class="tw-text-xl">วันนี้</p>
                <p class="tw-text-3xl">{{ current.temp | twoDigit }} °C</p>
                <p v-if="current.weather" class="tw-text-md">
                  {{ current.weather[0].description || "" }}
                </p>
              </div>
            </div>
            <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-mt-4">
              <div
                class="tw-flex tw-flex-row tw-w-full tw-bg-gray-500 tw-text-white tw-p-2 tw-gap-4 tw-rounded-md"
                v-for="(option, index) in options"
                :key="`${option}-${index}`"
              >
                <img
                  :src="require(`@/assets/weather_card/${option.icon}`)"
                  :alt="option.name"
                  class="tw-flex-none tw-w-6 tw-h-6"
                />
                <div class="tw-flex-auto">
                  <p>{{ option.value }}</p>
                  <p>{{ option.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="weather-forecast-content tw-text-white tw-flex tw-flex-row tw-gap-4 tw-h-auto tw-overflow-auto"
        >
          <div
            v-for="(item, index) in hourlyData"
            :key="index"
            class="tw-flex-none day-card tw-h-auto tw-w-auto tw-text-primary tw-bg-white tw-flex-col tw-justify-center tw-items-center tw-py-2 tw-text-center first:tw-bg-gray-300 first:tw-rounded-xl"
          >
            <p class="tw-flex-none has-text-primary" style="text-align:center">
              {{ item.date }}
            </p>
            <p
              class="tw-flex-none has-text-primary is-size-7"
              style="text-align:center;min-height:8px"
            >
              {{ item.Pop }}
            </p>
            <img class="tw-flex-none tw-w-16" :src="item.imgURL" />
            <p class="tw-flex-none has-text-primary" style="text-align:center">
              {{ item.avgTemp }}
            </p>
          </div>
        </div> -->
        <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-4 tw-min-w-0">
          <div
            class="weather-forecast-content tw-text-white tw-flex tw-flex-row tw-justify-between tw-gap-4 tw-h-auto tw-overflow-auto"
          >
            <div
              v-for="(item, index) in hourlyData"
              :key="index"
              class="tw-flex-none day-card tw-h-auto tw-w-16 lg:tw-w-20 tw-text-primary tw-bg-white tw-flex-col tw-justify-center tw-items-center tw-py-2 tw-text-center first:tw-bg-gray-300 first:tw-rounded-xl tw-mb-2"
            >
              <p
                class="tw-flex-none has-text-primary"
                style="text-align:center"
              >
                {{ item.date }}
              </p>
              <p
                class="tw-flex-none has-text-primary is-size-7"
                style="text-align:center;min-height:8px"
              >
                {{ item.Pop }}
              </p>
              <img class="tw-flex-none tw-w-full" :src="item.imgURL" />
              <p
                class="tw-flex-none has-text-primary"
                style="text-align:center"
              >
                {{ item.avgTemp }}
              </p>
            </div>
          </div>
          <div
            v-for="(item, index) in dailyData"
            :key="index"
            class="tw-flex-none tw-text-primary tw-flex tw-flex-row tw-items-center tw-justify-between tw-overflow-auto tw-bg-gray-200 tw-px-4 tw-rounded-xl tw-overflow-x-auto tw-min-w-0"
          >
            <p class="tw-flex-none tw-w-20" style="text-align:center">
              {{ item.date }}
            </p>
            <img style="max-width: 60px;" :src="item.imgURL" />
            <p class="tw-flex-none tw-w-32" style="text-align:center">
              {{ $t("Temperature") }} {{ item.avgTemp }}
            </p>
            <p class="tw-flex-none tw-w-24" style="text-align:center">
              {{ $t("Humidity") }} {{ item.Humidity }}
            </p>
            <p class="tw-flex-none tw-w-24" style="text-align:center">
              {{ item.Pop }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isShowEditWeatherLocation"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <EditWeatherLocation />
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import EditWeatherLocation from "@/components/EditWeatherLocation.vue";
dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekdays: [
    "วันอาทิตย์",
    "วันจันทร์",
    "วันอังคาร",
    "วันพุธ",
    "วันพฤหัสบดี",
    "วันศุกร์",
    "วันเสาร์",
  ],
  months: [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ],
});

export default {
  name: "WeatherForecast",
  components: {
    EditWeatherLocation,
  },
  data() {
    return {
      isShowEditWeatherLocation: false,
      hourly: [],
      daily: [],
      current: {},
      loading: true,
    };
  },
  computed: {
    weatherLocation() {
      return (
        this.$store.state.user.user.weatherLocation || {
          displayName: "กรุงเทพมหานคร",
          lat: 13.726,
          lon: 100.591,
        }
      );
    },
    displayWeatherLocation() {
      return this.weatherLocation?.displayName;
    },
    hourlyData() {
      return this.hourly.slice(0, 7).map((i, index) => {
        const weather = i.weather[0];
        const icon = weather.icon;
        const pop = Math.round(i.pop * 100);
        return {
          ...i,
          imgURL: `http://openweathermap.org/img/wn/${icon}@2x.png`,
          desc: weather.description,
          avgTemp: `${Math.round(i.temp)} °C`,
          Pop: pop > 20 ? `ฝน ${Math.round(i.pop * 100)}%` : "",
          // date: index === 0 ? "วันนี้" : dayjs.unix(i.dt).format("HH:00"),
          date: dayjs.unix(i.dt).format("HH:00"),
        };
      });
    },
    dailyData() {
      return this.daily.slice(1, 3).map((i, index) => {
        const weather = i.weather[0];
        const icon = weather.icon;
        const avgTemp = Math.round((i.temp.max + i.temp.min) / 2);
        const pop = Math.round(i.pop * 100);
        return {
          ...i,
          imgURL: `http://openweathermap.org/img/wn/${icon}@2x.png`,
          desc: weather.description,
          avgTemp: `${avgTemp} °C`,
          Humidity: `${i.humidity} %`,
          Pop: pop > 20 ? `ฝน ${Math.round(i.pop * 100)}%` : "",
          date: dayjs.unix(i.dt).format("dddd"),
        };
      });
    },
    options() {
      return [
        {
          name: "ความเร็วลม",
          type: "assets",
          icon: "wind.svg",
          value: `${this.current.wind_speed?.toFixed(2)} km/hr` || "N/A",
        },
        {
          name: "ความดัน",
          type: "assets",
          icon: "pressure.svg",
          value: `${this.current.pressure} mbar` || "N/A",
        },
        {
          name: "ความชื้น",
          type: "assets",
          icon: "water_drop.svg",
          value: `${this.current.humidity}%` || "N/A",
        },
      ];
    },
  },
  watch: {
    weatherLocation: {
      handler: function(value) {
        this.fetchWeatherData({
          lat: value.lat,
          lon: value.lon,
        });
      },
    },
    isShowEditWeatherLocation: {
      handler: function(value) {
        if (value == false) {
          this.fetchWeatherData({
            lat: this.weatherLocation.lat,
            lon: this.weatherLocation.lon,
          });
        }
      },
    },
  },
  mounted() {
    console.log("mounted");
    this.fetchWeatherData({
      lat: this.weatherLocation.lat,
      lon: this.weatherLocation.lon,
    });
    this.loading = false;
  },
  methods: {
    async fetchWeatherData(payload) {
      try {
        const result = await this.$store.dispatch(
          "weatherForecast/get",
          payload
        );
        console.log(result);

        this.current = result.current;
        this.daily = result.daily;
        this.hourly = result.hourly;
      } catch (error) {
        console.error(error);
      }
    },
    onOpenWeatherLocationDialog() {
      console.log("onOpenWeatherLocationDialog");
      this.isShowEditWeatherLocation = true;
    },
  },
  filters: {
    twoDigit(val) {
      if (!val) return "n/a";
      return val.toFixed(1);
    },
  },
};
</script>

<style>
/* * {
  outline: solid 1px red;
} */
</style>
