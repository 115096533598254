<template>
    <section
        class="hero has-background-white is-link is-fullheight-with-navbar"
    >
        <div class="is-grid is-justify-content-center">
            <div class="hero-body is-justify-content-center">
                <p class="title has-text-primary">
                    Scan QR Code
                </p>
            </div>
            <qrcode-stream
                v-if="enable"
                class="qr"
                @decode="onDecode"
            ></qrcode-stream>
        </div>
        <b-modal
            v-model="isComponentModalActive"
            has-modal-card
            full-screen
            :can-cancel="false"
        >
            <AddSensorQRCodeForm v-bind="formProps"></AddSensorQRCodeForm>
        </b-modal>
    </section>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import AddSensorQRCodeForm from "@/components/AddSensorQRCodeForm.vue";
export default {
    name: "ProfileAddSensor",
    components: {
        QrcodeStream,
        AddSensorQRCodeForm,
    },
    data() {
        return {
            enable: true,
            isComponentModalActive: false,
            formProps: {
                thingID: null,
                role: null,
                ownerID: null,
            },
        };
    },
    methods: {
        async onDecode(decodedString) {
            try {
                this.enable = false;
                const thingID = decodedString;

                // check existing thing
                const thingList = this.$store.getters["user/thingList"];
                if (thingList) {
                    if (thingList.some((i) => i.thingID === thingID)) {
                        console.log("aleady add");
                        this.$buefy.snackbar.open({
                            indefinite: true,
                            message: this.$t(
                                "You aleady had added this device"
                            ),
                            type: "is-warning",
                            position: "is-bottom",
                            actionText: "Retry",
                            onAction: () => {
                                console.log("Retry");
                                this.enable = true;
                            },
                        });
                        return;
                    }
                }

                const result = await this.$store.dispatch(
                    "addDevice/hasOwner",
                    {
                        thingID,
                    }
                );
                console.log("result: ", result);
                if (result.hasOwner) {
                    // add sensor as maintainer
                    this.formProps.thingID = thingID;
                    this.formProps.role = "Maintainer";
                    const owner = result.items.find((i) => i.role === "OWNER");
                    this.formProps.ownerID = owner.userID;
                } else {
                    // add sensor as owner
                    this.formProps.thingID = thingID;
                    this.formProps.role = "Owner";
                }
                this.isComponentModalActive = true;
            } catch (error) {
                console.log(error.constructor, error);
                switch (error.constructor) {
                    case TypeError:
                        this.$buefy.snackbar.open({
                            indefinite: true,
                            message: this.$t("This sensor does not exist."),
                            type: "is-warning",
                            position: "is-bottom",
                            actionText: "Retry",
                            onAction: () => {
                                console.log("Retry");
                                this.enable = true;
                            },
                        });
                        break;
                    default:
                        this.$buefy.snackbar.open({
                            indefinite: true,
                            message: this.$t(
                                "Something error, please try again later."
                            ),
                            position: "is-bottom",
                            actionText: "Retry",
                            onAction: () => {
                                console.log("Retry");
                                this.enable = true;
                            },
                        });
                        break;
                }
            }
        },
    },
};
</script>

<style scoped>
.qr {
    max-width: 480px;
    max-height: 360px;
}
</style>
