<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t("Aerator settings") }}</p>
        </header>
        <section class="modal-card-body" style="text-align: start">
            <!-- <b-field :label="`Select thing ID` | t" required>
                <b-select
                    :placeholder="`Select thing ID` | t"
                    expanded
                    v-model="anomalyDetectorTask.thingID"
                >
                    <option
                        v-for="item in thingList"
                        :value="item.thingID"
                        :key="item.thingID"
                    >
                        {{ item.thingID }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="`Select field` | t" required>
                <b-select
                    :placeholder="`Select field` | t"
                    expanded
                    v-model="anomalyDetectorTask.field"
                >
                    <option v-for="item in fieldList" :value="item" :key="item">
                        {{ item }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="`Select method` | t" required>
                <b-select
                    :placeholder="`Select method` | t"
                    expanded
                    v-model="anomalyDetectorTask.method"
                >
                    <option
                        v-for="item in methodList"
                        :value="item"
                        :key="item"
                    >
                        {{ item }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="`Sensitivity` | t">
                <b-slider
                    :step="10"
                    v-model="anomalyDetectorTask.sensitivity"
                    :custom-formatter="(val) => (val / 100).toString()"
                ></b-slider>
            </b-field> -->
            <b-field :label="`Start aerator when DO less than` | t">
                <b-numberinput
                    step="0.1"
                    :controls="false"
                    v-model="formData.DOStartAerator"
                    min="0"
                    max="20"
                >
                </b-numberinput>
            </b-field>
            <b-field :label="`Stop aerator when DO more than` | t">
                <b-numberinput
                    step="0.1"
                    :controls="false"
                    v-model="formData.DOStopAerator"
                    min="0"
                    max="20"
                >
                </b-numberinput>
            </b-field>
            <b-field :label="`Warn when DO lower than` | t">
                <b-numberinput
                    step="0.1"
                    :controls="false"
                    v-model="formData.DOWarning"
                    min="0"
                    max="20"
                >
                </b-numberinput>
            </b-field>

            <!-- <b-button class="mr-1" label="Calibrate DO" @click="onOpenCalibrateDO"/> -->
            <!-- <b-button label="Calibrate pH" @click="onOpenCalibratePH"/> -->
            <!-- <b-field :label="`Threshold` | t">
                <b-slider
                    :step="10"
                    v-model="anomalyDetectorTask.threshold"
                    :custom-formatter="(val) => (val / 100).toString()"
                ></b-slider>
            </b-field>
            <b-field :label="`Select alert` | t"></b-field>
            <b-field v-for="(item, index) in alertList" :key="index">
                <b-checkbox
                    v-model="anomalyDetectorTask.alerts"
                    :native-value="item"
                >
                    {{ item.name }}
                </b-checkbox>
            </b-field> -->
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button :label="`Cancel` | t" @click="$parent.close()" />
            <b-button
                :label="`Confirm` | t"
                type="is-primary"
                @click="onSave"
            />
        </footer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "EditControlSettingsForm",
    props: {
        thingID: String,
    },
    data() {
        return {
            formData: {
                DOStartAerator: null,
                DOStopAerator: null,
                DOWarning: null,
            },
            unwatchMessageCounter: null,
        };
    },
    computed: {
        ...mapGetters({
            messageCounter: "mq/messageCounter",
        }),
    },
    created() {
        this.unwatchMessageCounter = this.$watch("messageCounter", () => {
            console.log("new message");
            
            let count = 0;

            let shadowValue = this.$store.getters["mq/messageByTopic"](
                `${this.thingID}/shadow/DOStartAerator/get/accepted`
            );
            if (shadowValue) {
                this.formData.DOStartAerator = shadowValue.reported;
                count++;
            }

            shadowValue = this.$store.getters["mq/messageByTopic"](
                `${this.thingID}/shadow/DOStopAerator/get/accepted`
            );
            if (shadowValue) {
                this.formData.DOStopAerator = shadowValue.reported;
                count++;
            }

            shadowValue = this.$store.getters["mq/messageByTopic"](
                `${this.thingID}/shadow/DOWarning/get/accepted`
            );
            if (shadowValue) {
                this.formData.DOWarning = shadowValue.reported;
                count++;
            }

            if (this.unwatchMessageCounter && count >= 3) {
                this.unwatchMessageCounter();
            }
        });
    },
    methods: {
        async onSave() {
            console.log("onSave");

            if (this.formData.DOStartAerator != null) {
                await this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/DOStartAerator/update`,
                    payload: JSON.stringify({
                        desired: this.formData.DOStartAerator,
                    }),
                });
            }

            if (this.formData.DOStopAerator != null) {
                await this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/DOStopAerator/update`,
                    payload: JSON.stringify({
                        desired: this.formData.DOStopAerator,
                    }),
                });
            }

            if (this.formData.DOWarning != null) {
                await this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/DOWarning/update`,
                    payload: JSON.stringify({
                        desired: this.formData.DOWarning,
                    }),
                });
            }

            await this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/DOStartAerator/get`,
                payload: "0",
            });
            await this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/DOStopAerator/get`,
                payload: "0",
            });
            await this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/DOWarning/get`,
                payload: "0",
            });

            this.$parent.close();
        },
        onOpenCalibrateDO(){
            console.log("open calibrate DO")
            this.$parent.close();
            this.$emit("on-open-calibrate-do");
        },
        onOpenCalibratePH(){
            console.log("open calibrate PH")
            this.$parent.close();
            this.$emit("on-open-calibrate-ph");
        }
    },
};
</script>
