<template>
  <div class="relative columns m-0 p-0 is-multiline" style="min-height: 300px">
    <!-- <DeviceFieldCard
            v-for="sensor in sensorList"
            :thingID="thingID"
            :key="sensor.id"
            :title="sensor.name"
            :field="sensor.name"
            :unit="sensor.unit"
            class="column is-half-tablet"
        /> -->
    <DeviceFieldCardV2
      v-for="sensor in sensorList"
      :thingID="thingID"
      :key="sensor.id"
      :title="sensor.name"
      :field="sensor.name"
      :unit="sensor.unit"
      class="column is-full"
      :desc="sensor.desc"
    />
    <DeviceControlCard :thingID="thingID" class="column is-half-tablet" />
    <DeviceSettingsCard :thingID="thingID" class="column is-half-tablet" />
  </div>
</template>

<script>
// import DeviceFieldCard from "@/components/DeviceFieldCard.vue";
import DeviceFieldCardV2 from "@/components/v2/DeviceFieldCardV2.vue";
import DeviceControlCard from "@/components/DeviceControlCard.vue";
import DeviceSettingsCard from "@/components/DeviceSettingsCard.vue";
export default {
  name: "LOOKGOONG",
  components: {
    // DeviceFieldCard,
    DeviceFieldCardV2,
    DeviceControlCard,
    DeviceSettingsCard,
  },
  props: {
    config: {
      type: Object,
    },
  },
  computed: {
    thingID() {
      return this.$route.params["thingid"];
    },
    sensorList() {
      return this.config.field || [];
    },
  },
};
</script>
