<template>
    <div>
        <section class="hero is-small is-primary has-text-left">
            <div class="hero-body">
                <div class="is-flex is-justify-content-space-between mb-2">
                    <p class="title is-4">
                        {{ $t("Notification destination") }}
                    </p>

                    <b-button
                        size="is-small"
                        :label="addNotification"
                        :icon-left="'plus'"
                        @click="onAddLineNotify"
                    >
                    </b-button>

                    <!-- <b-dropdown aria-role="list" position="is-bottom-left">
                        <template #trigger="{ active }">
                            <b-button
                                size="is-small"
                                :label="actionLabel"
                                type="is-info"
                                :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                        </template>

                        <b-dropdown-item
                            @click="onAddLineNotify"
                            aria-role="listitem"
                            >{{ $t("Add Line notify") }}</b-dropdown-item
                        >
                    </b-dropdown> -->
                </div>

                <div class="columns is-multiline">
                    <div
                        class="column"
                        v-for="(item, index) in notificationList"
                        :key="index"
                    >
                        <NotifyCard
                            :notification="item"
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="hero is-small is-secondary has-text-left">
            <div class="hero-body">
                <div class="is-flex is-justify-content-space-between mb-2">
                    <p class="title is-4">
                        {{ $t("Rules") }}
                    </p>

                    <b-button
                        size="is-small"
                        :label="addRule"
                        type="is-primary"
                        :icon-left="'plus'"
                        @click="onAddRule"
                    >
                    </b-button>

                    <!-- <b-dropdown aria-role="list" position="is-bottom-left">
                        <template #trigger="{ active }">
                            <b-button
                                size="is-small"
                                :label="actionLabel"
                                type="is-info"
                                :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                        </template>

                        <b-dropdown-item
                            @click="onAddLineNotify"
                            aria-role="listitem"
                            >{{ $t("Add Line notify") }}</b-dropdown-item
                        >
                    </b-dropdown> -->
                </div>

                <div class="columns is-multiline">
                    <div
                        class="column"
                        v-for="(item, index) in ruleList"
                        :key="index"
                    >
                        <RuleCard :rule="item" />
                    </div>
                </div>
            </div>
        </section>

        <b-modal
            v-model="isComponentModalActive"
            has-modal-card
            full-screen
            :can-cancel="false"
        >
            <AddLineNotifyForm v-bind="formProps"></AddLineNotifyForm>
        </b-modal>
        <b-modal
            v-model="isRuleFormActive"
            has-modal-card
            full-screen
            :can-cancel="false"
        >
            <AddRuleForm v-bind="formProps"></AddRuleForm>
        </b-modal>
    </div>
</template>

<script>
import NotifyCard from "@/components/NotifyCard.vue";
import AddLineNotifyForm from "@/components/AddLineNotifyForm.vue";
import AddRuleForm from "@/components/AddRuleForm.vue";
import RuleCard from "@/components/RuleCard.vue";
export default {
    name: "ProfileNotifications",
    components: {
        AddLineNotifyForm,
        NotifyCard,
        AddRuleForm,
        RuleCard,
    },
    data() {
        return {
            // notificationList: [
            //     {
            //         name: "test1",
            //         onClick: () => {
            //             console.log();
            //             this.$router.push({
            //                 name: "ProfileNotificationDetail",
            //                 params: {
            //                     thingid: "456",
            //                 },
            //             });
            //         },
            //     },
            //     {
            //         name: "test2",
            //         onClick: () => {
            //             console.log();
            //             this.$router.push({
            //                 name: "ProfileNotificationDetail",
            //                 params: {
            //                     thingid: "123",
            //                 },
            //             });
            //         },
            //     },
            // ],
            isComponentModalActive: false,
            isRuleFormActive: false,
            formProps: {
                thingID: null,
                role: null,
            },
        };
    },
    computed: {
        actionLabel() {
            return this.$t("Actions");
        },
        addNotification() {
            return this.$t("Add notification");
        },
        addRule() {
            return this.$t("Add rule");
        },
        notificationList() {
            return this.$store.getters["user/alertList"].map((item) => {
                return {
                    ...item,
                    onClick: () => {
                        console.log();
                        this.$router.push({
                            name: "ProfileNotificationDetail",
                            params: {
                                thingid: "123",
                            },
                        });
                    },
                };
            });
        },
        ruleList() {
            return this.$store.getters["anomalyDetectorTask/ruleList"];
        },
    },
    mounted() {
        const user = this.$store.getters["auth/user"];
        const { username, attributes } = user;
        // const { email } = attributes;
        this.$store.dispatch("anomalyDetectorTask/getRuleList", {
            userID: username,
        });
    },
    methods: {
        onAddLineNotify() {
            this.isComponentModalActive = true;
        },
        onAddRule() {
            this.isRuleFormActive = true;
        },
    },
};
</script>

<style scoped></style>
