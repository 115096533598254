<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t("Add Line notify") }}</p>
        </header>
        <section class="modal-card-body">
            <b-field label="Notify name">
                <b-input v-model="name" placeholder="Notify name" required>
                </b-input>
            </b-field>

            <!-- <b-field label="Role">
                <b-input :value="role" placeholder="Role" required disabled>
                </b-input>
            </b-field> -->

            <!-- <p v-if="role === 'Maintainer'" class="has-text-danger" style="text-align:left">{{ $t("You can access the device after the owner accept your access request.") }}</p> -->
            <!-- <b-checkbox>Remember me</b-checkbox> -->
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button label="Cancel" @click="$parent.close()" />
            <b-button
                label="Confirm"
                type="is-primary"
                @click="onAddLineNotify"
            />
        </footer>
    </div>
</template>

<script>
export default {
    name: "AddLineNotifyForm",
    // props: ["name"],
    data() {
        return {
            name: null,
        };
    },
    methods: {
        async onAddLineNotify() {
            console.log([null, undefined, ""].indexOf(this.name));
            if ([null, undefined, ""].indexOf(this.name) >= 0) {
                return;
            }

            console.log("onAddLineNotify");
            try {
                this.redirectToLineNotify();
                // const user = this.$store.getters["auth/user"];
                // await this.$store.dispatch("addDevice/pair", {
                //     thingID: this.thingID,
                //     userID: user.username,
                //     role: this.role
                // });
                // this.$parent.close();
                // this.$router.go(-2);
            } catch (error) {
                console.error(error);
            }
        },
        redirectToLineNotify() {
            const response_type = "code";
            const client_id = process.env.VUE_APP_LINE_NOTIFY_CLIENTID;
            const redirect_uri = `${window.location.origin}/line-notify-redirect`;
            const scope = "notify";
            const state = btoa(JSON.stringify({
                username: this.$store.getters["auth/user"]["username"],
                name: this.name,
            }));
            window.location.replace(
                `https://notify-bot.line.me/oauth/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}&state=${state}`
            );
        },
    },
};
</script>
