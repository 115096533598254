<template>
  <footer
    style="position: fixed;bottom: 0px;left:0px; right:0px"
    class="footer"
  >
    <div class="content has-text-centered">
      <p>© 2022 <strong>avilonROBOTICS</strong></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
