<template>
  <div class="is-clickable is-primary">
    <div
      class="
        main-content main-card
        is-flex is-justify-content-center
        has-background-primary
      "
      @click.stop="onclick"
    >
      <div class="last-info-content dp-box">
        <b style="flex: 1" class="is-size-6 pt-2">
          {{ "Last day" | t }}
        </b>
        <div v-if="lastDay.max" class="last-info-content-box">
          <div class="is-flex last-info-content-text">
            <div style="flex: 1; width: 100%">{{ "Max" | t }}</div>
            <div style="flex: 1; width: 100%">
              {{ lastDay.max.Value }} {{ unit }}
            </div>
          </div>
          <div v-if="lastDay.max" class="is-flex last-info-content-text">
            <div style="flex:1; width: 100%">
              เวลา
            </div>
            <div style="flex: 1; width: 100%">
              {{ lastDay.max.DisplayTime }} น
            </div>
          </div>
        </div>
        <div v-if="lastDay.min" class="last-info-content-box">
          <div class="is-flex last-info-content-text">
            <div style="flex: 1; width: 100%">{{ "Min" | t }}</div>
            <div style="flex: 1; width: 100%">
              {{ lastDay.min.Value }} {{ unit }}
            </div>
          </div>
          <div v-if="lastDay.min" class="is-flex last-info-content-text">
            <div style="flex:1; width: 100%">
              เวลา
            </div>
            <div style="flex: 1; width: 100%">
              {{ lastDay.min.DisplayTime }} น
            </div>
          </div>
        </div>
      </div>
      <div class="current-info-content">
        <div class="is-size-4 has-text-white">
          {{ $t(title) }}
        </div>
        <div class="is-size-5 has-text-white">
          {{ desc }}
        </div>
        <div
          :class="
            `is-size-1 ${isWarning ? 'has-text-danger' : 'has-text-white'}`
          "
        >
          {{ currentValue | twoDigit }}
          <span class="is-size-5 has-text-white">{{ unit }}</span>
        </div>
      </div>

      <div
        style="flex: 2; padding: 0px 7px 7px 0px; position: relative"
        class="is-flex is-flex-direction-column"
      >
        <div class="main-last-update-display has-text-white">
          {{ lastUpdateDisplay }}
        </div>
        <div style="flex: 1; position: relative" @click.stop>
          <div
            style="
              position: absolute;
              top: 0px;
              bottom: 0px;
              left: 0px;
              right: 0px;
            "
            ref="chart"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="showTable">
      <TableFilter
        :filter-loading="filterLoading"
        @onApplyFilter="onApplyFilter"
        @onResetFilter="onResetFilter"
      ></TableFilter>
      <b-table
        ref="table"
        header-class="header-table"
        :data="data"
        hoverable
        striped
        narrowed
        :columns="columns"
        :mobile-cards="false"
      >
      </b-table>
      <div class="footer-action">
        <b-button size="is-small" type="is-info is-light" @click="onLoadMore">
          {{ $t("load more") }}
        </b-button>
        <b-button
          size="is-small"
          type="is-danger is-light"
          @click="showExport = true"
        >
          {{ $t("export") }}
        </b-button>
      </div>
      <div v-if="showExport" class="export">
        <ExportBox
          @on-close="showExport = false"
          @on-export="onExportCSV"
          :loading="exportLoading"
        ></ExportBox>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { aggregateHourly } from "@/utils/aggregate";
import toTwoDigit from "@/utils/toTwoDigit";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "@/graphql/subscriptions";
import Cronr from "cronr";
dayjs.extend(relativeTime);

const NO_OF_BAR = 15;
const NO_OF_BAR_MOBILE = 7;

export default {
  name: "DeviceFieldCardV2",
  props: {
    title: String,
    desc: String,
    clientid: String,
    serial: String,
    field: String,
    src: Array,
    info: Object,
    Point: Object,
    thingID: String,
    unit: String,
    id: Number,
    reported: Object,
  },
  components: {
    TableFilter: () => import("@/components/TableFilter.vue"),
    ExportBox: () => import("@/components/ExportBox.vue"),
  },
  data() {
    return {
      // isWarning: false,
      subscription: null,
      polling: null,
      lastUpdate: dayjs(),
      lastUpdateDisplay: "",
      showTable: false,
      showExport: false,
      chart: null,
      resizeObserver: null,
      showDetailIcon: true,
      columnsVisible: {
        name: { title: "Name", display: true },
        sold: { title: "Stock Sold", display: true },
        available: { title: "Stock Available", display: true },
        cleared: { title: "Stock Cleared", display: true },
      },
      currentValue: null,
      start: null,
      end: null,
      isNextPreviousDate: false,
      raw: [],
      lastDay: {
        max: null,
        min: null,
      },
      filterLoading: false,
      data: [
        // {
        //     date: "18/08/2021",
        //     time: "17:00",
        //     DO: 1.4,
        //     change: "+0.7",
        // },
        // {
        //     date: "18/08/2021",
        //     time: "18:00",
        //     DO: 2.1,
        //     change: "+1.1",
        // },
        // {
        //     date: "18/08/2021",
        //     time: "19:00",
        //     DO: 3.2,
        //     // change: ,
        // },
      ],
    };
  },
  computed: {
    columns() {
      return [
        {
          field: "Date",
          label: "Date",
          centered: true,
        },
        {
          field: "Time",
          label: "Time",
          centered: true,
        },
        {
          field: this.title,
          label: this.title,
          centered: true,
        },
        {
          field: "Change",
          label: "Change",
          centered: true,
        },
      ];
    },
    isWarning() {
      if (!this.reported) {
        return false;
      }
      switch (this.field) {
        case "DO":
          switch (this.id) {
            case 1:
              return this.currentValue < this.reported.PondDOWarn00;
            case 2:
              return this.currentValue < this.reported.PondDOWarn01;
            case 3:
              return this.currentValue < this.reported.PondDOWarn02;
            case 4:
              return this.currentValue < this.reported.PondDOWarn03;
            default:
              break;
          }
          return false;
        case "pH":
          switch (this.id) {
            case 1:
              return this.currentValue < this.reported.PondPhWarn00;
            case 2:
              return this.currentValue < this.reported.PondPhWarn01;
            case 3:
              return this.currentValue < this.reported.PondPhWarn02;
            case 4:
              return this.currentValue < this.reported.PondPhWarn03;
            default:
              break;
          }
          return false;
        case "TempWater":
          switch (this.id) {
            case 1:
              return this.currentValue > this.reported.PondTWWarn00;
            case 2:
              return this.currentValue > this.reported.PondTWWarn01;
            case 3:
              return this.currentValue > this.reported.PondTWWarn02;
            case 4:
              return this.currentValue > this.reported.PondTWWarn03;
            default:
              break;
          }
          return false;
        default:
          return false;
      }
    },
    // Hourly() {
    //     return aggregateHourly(this.data);
    // },
  },
  watch: {
    thingID: {
      immediate: false,
      handler: function() {
        this.cleanup();
        this.init();
      },
    },
  },
  filters: {
    twoDigit(value) {
      if ([null, undefined].indexOf(value) < 0) {
        return toTwoDigit(value);
      }
      return "n/a";
    },
  },
  mounted() {
    // this.lastUpdateDisplay = this.lastUpdate.fromNow();
    // setInterval(() => {
    //     this.lastUpdateDisplay = this.lastUpdate.fromNow();
    // }, 30000);
    this.init();
  },
  methods: {
    init() {
      // NOTE initial
      this.createChart();
      this.fetchData().then(() => {
        this.draw();
        this.calculateLastDayMinMax();
      });
      this.subscribe();
      this.polling = new Cronr("0 0,30 * * * *", () => {
        console.log("trigger!");
        this.fetchData();
        this.end = null;
        this.start = null;
      });
      this.polling.start();
    },
    async fetchData() {
      try {
        let result;
        if (this.id != null || this.id != undefined) {
          result = await this.$store.dispatch("timeseries/getLatestStream", {
            thingID: this.thingID,
            field: this.id + "#" + this.field,
          });
        } else {
          result = await this.$store.dispatch("timeseries/getLatestStream", {
            thingID: this.thingID,
            field: this.field,
          });
        }

        console.log(result);
        try {
          this.currentValue = result.Point.Value;
          this.lastUpdateDisplay = dayjs.unix(result.Point.Timestamp).fromNow();
          this.data = aggregateHourly(result.Points).reduceRight(
            (acc, i, index, origin) => {
              const p = i;
              p[this.field] = toTwoDigit(i.Value);
              if (origin[index - 1]) {
                const change = toTwoDigit(i.Value - origin[index - 1].Value);
                p.Change = (change <= 0 ? "" : "+") + change;
              }
              acc.push(p);
              return acc;
            },
            []
          );
          this.raw = result.Points;
        } catch (error) {
          console.warn(error);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async onLoadMore() {
      if (this.data.length <= 0) {
        return;
      }
      try {
        if (!this.isNextPreviousDate) {
          this.end = dayjs.unix(this.data[this.data.length - 1].Timestamp);
        }
        this.start = this.end.subtract(1, "day");
        console.log(this.start.date(), this.end.date());

        let result;
        if (this.id != null || this.id != undefined) {
          result = await this.$store.dispatch("timeseries/getTimeseries", {
            thingID: this.thingID,
            field: this.id + "#" + this.field,
            start: this.start.unix(),
            end: this.end.unix(),
          });
        } else {
          result = await this.$store.dispatch("timeseries/getTimeseries", {
            thingID: this.thingID,
            field: this.field,
            start: this.start.unix(),
            end: this.end.unix(),
          });
        }
        const newdata = aggregateHourly(result.Points).reduceRight(
          (acc, i, index, origin) => {
            const p = i;
            p[this.field] = toTwoDigit(i.Value);
            if (origin[index - 1]) {
              const change = toTwoDigit(i.Value - origin[index - 1].Value);
              p.Change = (change <= 0 ? "" : "+") + change;
            }
            acc.push(p);
            return acc;
          },
          []
        );
        this.raw = result.Points;
        this.data = this.data.concat(newdata);
        this.isNextPreviousDate = false;
      } catch (error) {
        console.error(error);
        this.end = this.end.subtract(1, "day");
        this.isNextPreviousDate = true;
      }
    },
    calculateLastDayMinMax() {
      console.log("calculateLastDayMinMax");

      const today = dayjs();
      const startOfToday = today.startOf("day");
      const startOfLastday = startOfToday.subtract(1, "day");

      const lastDayData = this.raw.filter((x) => {
        if (
          startOfToday.unix() > x.Timestamp &&
          startOfLastday.unix() < x.Timestamp
        ) {
          return true;
        }
        return false;
      });

      try {
        let max = lastDayData.reduce((a, b) => (a.Value > b.Value ? a : b));
        let min = lastDayData.reduce((a, b) => (a.Value < b.Value ? a : b));
        max.Value = max.Value.toFixed(2);
        min.Value = min.Value.toFixed(2);
        max.DisplayTime = dayjs.unix(max.Timestamp).format("HH:mm");
        min.DisplayTime = dayjs.unix(min.Timestamp).format("HH:mm");
        this.lastDay.max = max;
        this.lastDay.min = min;
      } catch (error) {
        console.warn(error);
      }
    },
    onclick() {
      this.showTable = !this.showTable;
    },
    createChart() {
      this.chart = echarts.init(this.$refs.chart);
      this.resizeObserver = new ResizeObserver((entries) => {
        // console.log("Size changed", entries[0].contentRect);
        // let { width, height } = entries[0].contentRect;
        // this.w = width;
        // this.h = height;
        this.chart.resize();
        //   entries[0].contentRect
      });
      this.resizeObserver.observe(this.$refs.chart);
    },
    draw() {
      const bar = window.innerWidth < 900 ? NO_OF_BAR_MOBILE : NO_OF_BAR;
      let data = this.raw.slice(-bar);

      if (data.length < bar) {
        for (let index = data.length; index <= bar; index++) {
          data.unshift({
            Timestamp: null,
            Value: null,
          });
        }
      }

      let option = {
        color: ["#FFFFFF"],
        xAxis: {
          show: true,
          type: "category",
          data: data.map((i) => {
            if (i.Timestamp) {
              return dayjs.unix(i.Timestamp).format("HH:mm");
            }
            return "";
          }),
          // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            show: true,
            color: "#ffff",
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          position: "right",
          show: true,
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
            color: "#ffffff",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        grid: {
          // show: true,
          // left: "0px",
          // right: "0px",
          // bottom: "0px",
          top: "35px",
          left: "10px",
          right: "35px",
          bottom: "25px",
        },
        tooltip: {
          show: true,
          // trigger: "axis",
        },
        // legend: {
        //     data: ["蒸发量", "降水量"],
        // },
        // toolbox: {
        //     show: true,
        //     feature: {
        //         dataView: { show: true, readOnly: false },
        //         magicType: { show: true, type: ["line", "bar"] },
        //         restore: { show: true },
        //         saveAsImage: { show: true },
        //     },
        // },
        series: [
          {
            data: data.map((i) => {
              if (i.Value) {
                return toTwoDigit(i.Value);
              }
              return null;
            }),
            type: "bar",
            // coordinateSystem: 'polar',
            backgroundStyle: {
              borderRadius: 10,
              borderColor: "#000000",
            },
            roundCap: true,
            itemStyle: {
              emphasis: {
                barBorderRadius: [4, 4],
                color: "hsl(171, 100%, 41%)",
              },
              normal: {
                barBorderRadius: [4, 4, 4, 4],
              },
            },
          },
        ],
      };

      option && this.chart.setOption(option);
    },
    subscribe() {
      if (this.id != null || this.id != undefined) {
        this.subscription = API.graphql(
          graphqlOperation(
            subscriptions.onCreateThingTimeseriesByThingIdAndFieldName,
            {
              thingID: this.thingID,
              fieldName: this.id + "#" + this.field,
            }
          )
        ).subscribe({
          next: ({ provider, value }) => {
            console.log({ provider, value });
            const point = {
              Timestamp:
                value.data.onCreateThingTimeseriesByThingIDAndFieldName
                  .timestamp,
              Value:
                value.data.onCreateThingTimeseriesByThingIDAndFieldName.mean,
            };
            this.raw = [...this.raw, point];
            this.currentValue = point.Value;
            this.lastUpdateDisplay = dayjs.unix(point.Timestamp).fromNow();
            this.draw();
          },
          error: (error) => console.warn(error),
        });
      } else {
        this.subscription = API.graphql(
          graphqlOperation(
            subscriptions.onCreateThingTimeseriesByThingIdAndFieldName,
            {
              thingID: this.thingID,
              fieldName: this.field,
            }
          )
        ).subscribe({
          next: ({ provider, value }) => {
            console.log({ provider, value });
            const point = {
              Timestamp:
                value.data.onCreateThingTimeseriesByThingIDAndFieldName
                  .timestamp,
              Value:
                value.data.onCreateThingTimeseriesByThingIDAndFieldName.mean,
            };
            this.raw = [...this.raw, point];
            this.currentValue = point.Value;
            this.lastUpdateDisplay = dayjs.unix(point.Timestamp).fromNow();
            this.draw();
          },
          error: (error) => console.warn(error),
        });
      }
    },
    cleanup() {
      console.log("cancel polling and unsubscribe");
      this.subscription && this.subscription.unsubscribe();
      this.polling && this.polling.stop();
    },
    async onApplyFilter(filter) {
      console.log("onApplyFilter: ", filter);
      try {
        this.filterLoading = true;
        const startDate = dayjs(filter.start)
          .startOf("day")
          .add(7, "hour")
          .unix();
        const endDate = dayjs(filter.end)
          .endOf("day")
          .add(7, "hour")
          .unix();
        console.log("filter ", startDate, endDate);
        let result;
        if (this.id != null || this.id != undefined) {
          result = await this.$store.dispatch("timeseries/getTimeseries", {
            thingID: this.thingID,
            field: this.id + "#" + this.field,
            start: startDate,
            end: endDate,
          });
        } else {
          result = await this.$store.dispatch("timeseries/getTimeseries", {
            thingID: this.thingID,
            field: this.field,
            start: startDate,
            end: endDate,
          });
        }

        const newdata = aggregateHourly(result.Points).reduceRight(
          (acc, i, index, origin) => {
            const p = i;
            p[this.field] = toTwoDigit(i.Value);
            if (origin[index - 1]) {
              const change = toTwoDigit(i.Value - origin[index - 1].Value);
              p.Change = (change <= 0 ? "" : "+") + change;
            }
            acc.push(p);
            return acc;
          },
          []
        );

        this.data = newdata;

        console.log(result);
      } catch (error) {
        this.data = [];
        console.log(error);
      } finally {
        this.filterLoading = false;
      }
    },
    async onExportCSV(params) {
      console.log("onExportCSV", params);
      try {
        this.exportLoading = true;

        //Create parameter
        const startDate = dayjs(params.start)
          .startOf("day")
          .add(7, "hour")
          .unix();
        const endDate = dayjs(params.end)
          .endOf("day")
          .add(7, "hour")
          .unix();
        console.log("filter ", startDate, endDate);

        //Fetch data
        let result;
        if (this.id != null || this.id != undefined) {
          result = await this.$store.dispatch("timeseries/getTimeseries", {
            thingID: this.thingID,
            field: this.id + "#" + this.field,
            start: startDate,
            end: endDate,
          });
        } else {
          result = await this.$store.dispatch("timeseries/getTimeseries", {
            thingID: this.thingID,
            field: this.field,
            start: startDate,
            end: endDate,
          });
        }
        console.log("export result", result);

        const p = result.Points.map((point) => {
          const ux = dayjs.unix(point.Timestamp).second(0);
          return {
            Date: ux.format("DD/MM/BB"),
            Time: ux.format("HH:mm"),
            Timestamp: ux,
            Value: point.Value,
          };
        });

        // console.log("point p", p);

        const newdata = p.reduceRight((acc, i, index, origin) => {
          const p = i;
          p[this.field] = toTwoDigit(i.Value);
          p["TwoDigitValue"] = toTwoDigit(i.Value);
          if (origin[index - 1]) {
            const change = toTwoDigit(i.Value - origin[index - 1].Value);
            p.Change = (change <= 0 ? "" : "+") + change;
          }
          acc.push(p);
          return acc;
        }, []);

        console.log("newdata", newdata);

        // Convert to csv
        // - Create header
        const headers = {
          date: "Date",
          time: "Time",
          value: `${this.field}`,
          change: "Change",
        };

        const fileTitle = `report-field-${
          this.id ? this.id + "#" + this.field : this.field
        }_(${dayjs.unix(startDate)}-${dayjs.unix(endDate)})`;

        let itemsFormatted = [];

        newdata.forEach((data) => {
          const newdate = data.Date.split("/");
          itemsFormatted.push({
            date: `${newdate[0]}/${newdate[1]}/25${newdate[2]}`,
            time: data.Time,
            value: data.TwoDigitValue,
            change: data.Change,
          });
        });
        console.log("itemsFormatted", itemsFormatted);

        this.exportCSVFile(headers, itemsFormatted, fileTitle);
      } catch (error) {
        console.error(error);
      } finally {
        this.exportLoading = false;
      }
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }
      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);
      var csv = this.convertToCSV(jsonObject);
      var exportedFilenmae = fileTitle + ".csv" || "export.csv";
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        // console.log("link", link.download)
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";
      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";
          line += array[i][index];
        }
        str += line + "\r\n";
      }
      return str;
    },
    onResetFilter() {
      console.log("onResetFilter");
      this.init();
    },
  },
  beforeDestroy() {
    this.cleanup();
  },
};
</script>

<style scoped>
.main-card {
  overflow: hidden;
  border-radius: 15px;
  flex-direction: row;
}
.main-content {
  position: relative;
  /* aspect-ratio: 5; */
  /* border: 1px solid #1c75e0; */
  padding: 10px;
  height: 200px;
}
.main-title {
  /* position: absolute; */
  font-size: 13.33px;
}
.main-value-title {
  font-size: 0.3em;
  /* align-self: center; */
}
.main-last-update-display {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 11px;
}
.header-table {
  border: 1px solid green;
}

.current-info-content {
  padding: 10px;
  flex: 1;
  align-self: center;
}
.last-info-content {
  padding: 10px;
  flex: 1;
  color: white;
  align-self: center;
  /* border-right: 1px solid white; */
}
.chart-content {
  flex: 2;
  position: relative;
}
.footer-action {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}
.dp-box {
  flex: 1;
  border: 1px solid rgba(255, 255, 255, 0.152);
  height: -webkit-fill-available;
  margin: 10px;
  padding: 5px;
  border-radius: 1em;
}

@media screen and (max-width: 900px) {
  .main-card {
    flex-direction: column;
  }
  .main-content {
    height: 450px;
  }
  .last-info-content {
    flex-direction: column;
    display: flex;
    width: 100%;
    /* border-right: none; */
  }
  .last-info-content-box {
    display: flex;
    flex-direction: row;
  }
  .last-info-content-text {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  /* .dp-box {
    border: none;
  } */
}
</style>
