<template>
  <div>
    <section class="hero is-small is-primary has-text-left">
      <div class="hero-body">
        <div class="is-flex is-justify-content-space-between mb-2">
          <p class="title is-4">
            {{ $t("Dashboard") }}
          </p>

          <!-- <b-dropdown aria-role="list" position="is-bottom-left">
                        <template #trigger="{ active }">
                            <b-button
                                size="is-small"
                                :label="actionLabel"
                                type="is-info"
                                :icon-right="active ? 'caret-up' : 'caret-down'"
                            />
                        </template>

                        <b-dropdown-item
                            @click="onAddDevice"
                            aria-role="listitem"
                            >{{ $t("Add device") }}</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="onNotification"
                            aria-role="listitem"
                            >{{ $t("Notifications") }}</b-dropdown-item
                        >
                    </b-dropdown> -->
        </div>

        <div class="columns is-multiline">
          <div
            class="column is-4-tablet is-12-mobile is-3-desktop is-2-fullhd"
            v-for="(item, index) in thingList"
            :key="index"
          >
            <DeviceCard :device="item" @on-click="item.onClick" />
          </div>
          <div class="column is-full">
            <WeatherForecast />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeviceCard from "@/components/v2/DeviceCard.vue";
// import WeatherForecast from "@/components/WeatherForecast.vue";
import WeatherForecast from "@/components/v2/WeatherForecastV2.vue";
export default {
  name: "ProfileRoot",
  components: {
    DeviceCard,
    WeatherForecast,
  },
  data() {
    return {
      dashboardList: [
        {
          name: "test1",
          onClick: () => {
            console.log();
            this.$router.push({
              name: "ProfileDevice",
              params: {
                thingid: "456",
              },
            });
          },
        },
        {
          name: "test2",
          onClick: () => {
            console.log();
            this.$router.push({
              name: "ProfileDevice",
              params: {
                thingid: "123",
              },
            });
          },
        },
      ],
    };
  },
  computed: {
    actionLabel() {
      return this.$t("Actions");
    },
    thingList() {
      const list = this.$store.getters["user/thingList"];
      return list.map((item) => {
        return {
          name: item.name || item.thingID,
          thingID: item.thingID,
          onClick: () => {
            this.$router.push({
              name: "ProfileDevice",
              params: {
                thingid: item.thingID,
              },
            });
          },
        };
      });
    },
    // ...mapGetters({
    //     dashboardList: "user/dashboardList",
    // }),
  },
  methods: {
    onAddDevice() {
      console.log("onAddDevice");
      this.$router.push({
        name: "ProfileAddSensorInstruction",
      });
    },
    onNotification() {
      this.$router.push({
        name: "ProfileNotifications",
      });
    },
  },
};
</script>
