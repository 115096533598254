<template>
    <div>
        Info
    </div>
</template>

<script>
export default {
    name: "SystemsMessageInfo"
}
</script>