import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra)

const groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export function aggregateHourly(points) {
    const p = points.map((point) => {
        return {
            Timestamp: dayjs
                .unix(point.Timestamp)
                .minute(0)
                .second(0),
            Value: point.Value,
        };
    });
    console.log(p);
    const result = groupBy(p, "Timestamp");
    console.log(result);
    const hourly = Object.keys(result).map((hour) => {
        const sum = result[hour].reduce((acc, p) => {
            acc += p.Value;
            return acc;
        }, 0);

        const d = dayjs(hour);
        return {
            Date: d.format("DD/MM/BB"),
            Time: d.format("HH:00"),
            Timestamp: d.unix(),
            Value: sum / result[hour].length,
        };
    });

    return hourly;
}
