<template>
    <div class="is-clickable is-primary">
        <div class="main-content main-card has-background-primary">
            <div
                class="is-size-4 is-flex is-flex-direction-row has-text-white mx-4 my-2"
            >
                <div class="pt-2">
                    {{ $t("Other Settings") }}
                </div>

                <div style="flex:1" />

                <!-- <b-button
                    @click="onOpenSettingsDialog"
                    size="is-medium"
                    icon-right="cog"
                ></b-button> -->
            </div>

            <div class="level is-mobile mb-2 mx-2">
                <!-- <div class="level-item">
                    <b-button @click="onOn" expanded size="is-medium">{{
                        $t("On")
                    }}</b-button>
                </div>
                <div class="level-item">
                    <b-button @click="onOff" expanded size="is-medium">{{
                        $t("Off")
                    }}</b-button>
                </div>
                <div class="level-item">
                    <b-button @click="onAuto" expanded size="is-medium">{{
                        $t("Auto")
                    }}</b-button>
                </div> -->
                <div class="level-item">
                    <b-button
                        @click="onOpenCalibrateDO"
                        expanded
                        icon-right="tachometer-alt"
                        :label="`Calibrate DO` | t"
                    ></b-button>
                </div>
                <div class="level-item">
                    <b-button
                        @click="onOpenCalibratePH"
                        expanded
                        icon-right="tachometer-alt"
                        :label="`Calibrate pH` | t"
                    ></b-button>
                </div>
            </div>
        </div>

        <b-modal
            v-model="isShowCalibrateDO"
            has-modal-card
            full-screen
            :can-cancel="false"
        >
            <CalibrateDO :thingID="thingID" />
        </b-modal>

        <b-modal
            v-model="isShowCalibratePH"
            has-modal-card
            full-screen
            :can-cancel="false"
        >
            <CalibratePH :thingID="thingID" />
        </b-modal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as echarts from "echarts";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { aggregateHourly } from "@/utils/aggregate";
import toTwoDigit from "@/utils/toTwoDigit";
import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "@/graphql/subscriptions";
import Cronr from "cronr";
import { debounce } from "debounce";
// import EditControlSettingsForm from "@/components/EditControlSettingsForm.vue";
import CalibrateDO from "@/components/CalibrateDO.vue";
import CalibratePH from "@/components/CalibratePH.vue";
dayjs.extend(relativeTime);

export default {
    name: "DeviceSettingsCard",
    components: {
        // EditControlSettingsForm,
        CalibrateDO,
        CalibratePH,
    },
    props: {
        thingID: String,
    },
    data() {
        return {
            isShowEditControlSettings: false,
            isShowCalibrateDO: false,
            isShowCalibratePH: false,
            currentState: "n/a",
        };
    },
    computed: {
        ...mapGetters({
            messageCounter: "mq/messageCounter",
        }),
    },
    watch: {
        messageCounter() {
            let val = this.$store.getters["mq/messageByTopic"](
                `${this.thingID}/aeratorState`
            );
            if (val != null) {
                switch (val) {
                    case 0:
                        this.currentState = "Off";
                        return;
                    case 1:
                        this.currentState = "On";
                        return;
                    default:
                        this.currentState = "n/a";
                        return;
                }
            }
            this.currentState = "n/a";
        },
    },
    mounted() {
        console.log("subscribe aeratorState");
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/aeratorState`
        );
    },
    methods: {
        onOff: debounce(function() {
            console.log("onOff");

            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/AeratorMode/update`,
                payload: JSON.stringify({
                    desired: 0,
                }),
            });
        }, 300),
        onOn: debounce(function() {
            console.log("onOn");
            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/AeratorMode/update`,
                payload: JSON.stringify({
                    desired: 1,
                }),
            });
        }, 300),
        onAuto: debounce(function() {
            console.log("onAuto");
            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/AeratorMode/update`,
                payload: JSON.stringify({
                    desired: 2,
                }),
            });
        }, 300),
        async onOpenSettingsDialog() {
            // console.log("open settings dialog", this.thingID);
            // await this.$store.dispatch(
            //     "mq/MqttSubscribe",
            //     `${this.thingID}/shadow/DOStartAerator/get/accepted`
            // );
            // await this.$store.dispatch(
            //     "mq/MqttSubscribe",
            //     `${this.thingID}/shadow/DOStopAerator/get/accepted`
            // );
            // await this.$store.dispatch(
            //     "mq/MqttSubscribe",
            //     `${this.thingID}/shadow/DOWarning/get/accepted`
            // );
            // await this.$store.dispatch("mq/MqttPublish", {
            //     topic: `${this.thingID}/shadow/DOStartAerator/get`,
            //     payload: "0",
            // });
            // await this.$store.dispatch("mq/MqttPublish", {
            //     topic: `${this.thingID}/shadow/DOStopAerator/get`,
            //     payload: "0",
            // });
            // await this.$store.dispatch("mq/MqttPublish", {
            //     topic: `${this.thingID}/shadow/DOWarning/get`,
            //     payload: "0",
            // });
            this.isShowEditControlSettings = true;
        },
        onOpenCalibrateDO() {
            console.log("onOpenCalibrateDO");
            this.isShowCalibrateDO = true;
        },
        onOpenCalibratePH() {
            console.log("onOpenCalibratePH");
            this.isShowCalibratePH = true;
        },
    },
    beforeDestroy() {
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/DOStartAerator/get/accepted`
        );
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/DOStopAerator/get/accepted`
        );
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/DOWarning/get/accepted`
        );
    },
};
</script>

<style scoped>
.main-card {
    overflow: hidden;
    border-radius: 15px;
}
.main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    /* aspect-ratio: 3; */
    /* border: 1px solid #1c75e0; */
}
.main-title {
    /* position: absolute; */
    font-size: 13.33px;
}
.main-value-title {
    font-size: 0.3em;
    /* align-self: center; */
}
.main-last-update-display {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 11px;
}
.header-table {
    border: 1px solid green;
}
</style>
