<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t("Calibrate pH") }}</p>
        </header>
        <section class="modal-card-body" style="text-align: start">
            <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode"
            >
                <b-step-item size="is-small" step="1">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Prepare
                    </div>
                    Before calibrating the probe, disconnect the temperature
                    probe from the transmitter. Temperature compensation is not
                    part of calibration. Disconnecting the temperature probe
                    makes the calibration process easier.
                </b-step-item>

                <b-step-item size="is-small" step="2">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Calibrate pH7
                    </div>

                    <div>
                        Calibrating to pH 7.00 will reset the stored
                        calibration. If two, or three point calibration has been
                        done in the past, it must now be redone.
                    </div>

                    <div class="mt-3">
                        You can skip this step.
                    </div>

                    <div class="has-text-centered mt-6">
                        <b-button
                            type="is-danger"
                            :loading="calibratePHMidLoading"
                            label="Calibrate pH7"
                            @click="onCalibratePHMid"
                        />
                    </div>
                </b-step-item>

                <b-step-item size="is-small" step="3">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Calibrate pH4
                    </div>

                    <div></div>

                    <div class="has-text-centered mt-6">
                        <b-button
                            type="is-danger"
                            :loading="calibratePHLowLoading"
                            label="Calibrate pH4"
                            @click="onCalibratePHLow"
                        />
                    </div>
                </b-step-item>

                <b-step-item size="is-small" step="4">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Calibrate pH10
                    </div>

                    <div></div>

                    <div class="has-text-centered mt-6">
                        <b-button
                            type="is-danger"
                            :loading="calibratePHHighLoading"
                            label="Calibrate pH10"
                            @click="onCalibratePHHigh"
                        />
                    </div>
                </b-step-item>

                <b-step-item size="is-small" step="5">
                    <div
                        class="is-size-5 has-text-weight-semibold has-text-centered"
                    >
                        Finish
                    </div>
                    Calibration completed.
                </b-step-item>

                <template #navigation="{previous, next}">
                    <b-button
                        class="mr-1"
                        outlined
                        icon-left="arrow-left"
                        :disabled="previous.disabled"
                        @click.prevent="previous.action"
                    >
                        Previous
                    </b-button>
                    <b-button
                        outlined
                        icon-right="arrow-right"
                        :disabled="next.disabled"
                        @click.prevent="next.action"
                    >
                        Next
                    </b-button>
                </template>
            </b-steps>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button :label="`Close` | t" type="is-primary" @click="onClose" />
        </footer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "CalibratePH",
    props: {
        thingID: String,
    },
    data() {
        return {
            activeStep: 0,

            showSocial: false,
            isAnimated: true,
            isRounded: true,
            isStepsClickable: false,

            hasNavigation: true,
            customNavigation: false,
            isProfileSuccess: false,

            prevIcon: "chevron-left",
            nextIcon: "chevron-right",
            labelPosition: "bottom",
            mobileMode: null,

            calibratePHLowLoading: false,
            calibratePHMidLoading: false,
            calibratePHHighLoading: false,

            CALPHLowGetAcceptedJson: null,
            CALPHLowUpdateAccepted: null,

            CALPHMidGetAcceptedJson: null,
            CALPHMidUpdateAccepted: null,

            CALPHHighGetAcceptedJson: null,
            CALPHHighUpdateAccepted: null,
        };
    },
    computed: {
        ...mapGetters({
            messageCounter: "mq/messageCounter",
        }),
    },
    watch: {
        messageCounter() {
            // low
            this.CALPHLowGetAcceptedJson = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALPHLow/get/accepted/json`);
            this.CALPHLowUpdateAccepted = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALPHLow/update/accepted`);

            // mid
            this.CALPHMidGetAcceptedJson = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALPHMid/get/accepted/json`);
            this.CALPHMidUpdateAccepted = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALPHMid/update/accepted`);

            // high
            this.CALPHHighGetAcceptedJson = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALPHHigh/get/accepted/json`);
            this.CALPHHighUpdateAccepted = this.$store.getters[
                "mq/messageByTopic"
            ](`${this.thingID}/shadow/CALPHHigh/update/accepted`);
        },
        CALPHLowGetAcceptedJson: {
            handler: function(value) {
                console.log("CALPHLowGetAcceptedJson update!", value);
                if (value) {
                    if (value.desired == 0) {
                        this.calibratePHLowLoading = false;
                    }
                }
            },
        },
        CALPHLowUpdateAccepted: {
            handler: function(value) {
                console.log("CALPHLowUpdateAccepted update!", value);
                this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/CALPHLow/get/json`,
                    payload: "0",
                });
            },
        },
        CALPHMidGetAcceptedJson: {
            handler: function(value) {
                console.log("CALPHMidGetAcceptedJson update!", value);
                if (value) {
                    if (value.desired == 0) {
                        this.calibratePHMidLoading = false;
                    }
                }
            },
        },
        CALPHMidUpdateAccepted: {
            handler: function(value) {
                console.log("CALPHMidUpdateAccepted update!", value);
                this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/CALPHMid/get/json`,
                    payload: "0",
                });
            },
        },
        CALPHHighGetAcceptedJson: {
            handler: function(value) {
                console.log("CALPHHighGetAcceptedJson update!", value);
                if (value) {
                    if (value.desired == 0) {
                        this.calibratePHHighLoading = false;
                    }
                }
            },
        },
        CALPHHighUpdateAccepted: {
            handler: function(value) {
                console.log("CALPHHighUpdateAccepted update!", value);
                this.$store.dispatch("mq/MqttPublish", {
                    topic: `${this.thingID}/shadow/CALPHHigh/get/json`,
                    payload: "0",
                });
            },
        },
    },
    mounted() {
        // subscribe low
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHLow/update/delta/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHLow/get/accepted/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHLow/update/accepted`
        );

        // subscribe mid
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHMid/update/delta/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHMid/get/accepted/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHMid/update/accepted`
        );

        // subscribe high
        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHHigh/update/delta/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHHigh/get/accepted/json`
        );

        this.$store.dispatch(
            "mq/MqttSubscribe",
            `${this.thingID}/shadow/CALPHHigh/update/accepted`
        );

        // publish
        this.$store.dispatch("mq/MqttPublish", {
            topic: `${this.thingID}/shadow/CALPHLow/get/json`,
            payload: "0",
        });
        this.$store.dispatch("mq/MqttPublish", {
            topic: `${this.thingID}/shadow/CALPHMid/get/json`,
            payload: "0",
        });
        this.$store.dispatch("mq/MqttPublish", {
            topic: `${this.thingID}/shadow/CALPHHigh/get/json`,
            payload: "0",
        });
    },
    methods: {
        onCalibratePHLow() {
            this.calibratePHLowLoading = true;

            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/CALPHLow/update`,
                payload: JSON.stringify({
                    desired: 1,
                    delta: 1,
                }),
            });

            // setTimeout(() => {
            //     this.calibratePHLowLoading = false;
            // }, 3000);
        },
        onCalibratePHMid() {
            this.calibratePHMidLoading = true;

            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/CALPHMid/update`,
                payload: JSON.stringify({
                    desired: 1,
                    delta: 1,
                }),
            });

            // setTimeout(() => {
            //     this.calibratePHMidLoading = false;
            // }, 3000);
        },
        onCalibratePHHigh() {
            this.calibratePHHighLoading = true;

            this.$store.dispatch("mq/MqttPublish", {
                topic: `${this.thingID}/shadow/CALPHHigh/update`,
                payload: JSON.stringify({
                    desired: 1,
                    delta: 1,
                }),
            });

            // setTimeout(() => {
            //     this.calibratePHHighLoading = false;
            // }, 3000);
        },
        async onClose() {
            this.$parent.close();
        },
    },
};
</script>
