<template>
  <div class="card tw-relative tw-pb-10 tw-py-4">
    <div
      @click.prevent="onClick"
      class="tw-bg-white tw-rounded-md tw-flex tw-flex-row sm:tw-flex-col tw-text-left sm:tw-text-center tw-items-center tw-w-full tw-text-primary tw-relative tw-cursor-pointer"
    >
      <img
        class="tw-h-24 sm:tw-h-28 tw-w-auto"
        :src="type === 'SOOB' ? iconSoob : icon"
        alt=""
      />
      <div class="detial">
        <p class="tw-text-lg tw-text-primary tw-my-1">
          {{ device.name }}
        </p>
        <div :class="`tw-flex`">
          <p>{{ $t("Connection Status") }} :</p>
          <p :class="connected | connectivityStyle">
            {{ connected | connectivity | t }}
          </p>
        </div>
        <div class="is-flex is-align-items-baseline">
          <p>{{ $t("Alert Status") }}:</p>
          <template v-if="lastAlert">
            <b class="mx-1 has-text-danger"
              >{{ lastAlert.field }} {{ lastAlertValue }}</b
            >
          </template>
        </div>
      </div>
    </div>
    <div
      v-if="expand"
      class="expanded-slot tw-w-full tw-text-left tw-flex tw-flex-col tw-bg-gray-100 tw-mt-8 tw-relative"
    >
      <select
        v-if="pondId"
        v-model="pondId"
        class="tw-bg-primary tw-w-min tw-self-center tw-absolute tw-transform tw--translate-y-1/2 tw-text-white tw-px-4 tw-rounded-lg"
        label="countryName"
      >
        <option v-for="(p, index) in pondList" :key="index" :value="p.id"
          >บ่อที่ {{ p.id }}</option
        >
      </select>
      <div class="list-value tw-px-2 tw-py-4">
        <div
          v-for="(field, index) in fieldData"
          :key="index"
          class="tw-flex tw-flex-rows tw-justify-between"
        >
          <p class="tw-text-primary tw-flex">
            {{ $t(field.name) }}
          </p>
          <p class="tw-flex">{{ field.currentValue }} {{ field.unit }}</p>
        </div>
      </div>
      <button
        type="button"
        class="tw-bg-gray-500 tw-px-4 tw-py-1 tw-mb-2 tw-w-auto tw-text-white tw-rounded-md tw-self-center tw-flex tw-items-center"
        @click.prevent="editMode = !editMode"
      >
        <p>เปลี่ยนชื่อเครื่อง</p>
        <img
          class="tw-pl-4 tw-h-4 tw-w-8"
          :src="require(`@/assets/v2/icon/pen-solid.svg`)"
          alt=""
        />
      </button>
      <div v-if="editMode" class="tw-flex tw-mt-4 tw-mx-2 tw-gap-2 tw-mb-4">
        <input
          class="tw-w-full tw-py-1 tw-pl-4 tw-text-sm tw-pr-16 tw-border-2 tw-border-gray-200 tw-rounded-lg"
          type="email"
          placeholder="ชื่อใหม่"
          v-model="newName"
        />

        <button
          class=" tw-p-2 tw-text-white tw-bg-primary tw-rounded-full tw-right-2"
          type="button"
          @click="onSaveNewName"
        >
          <svg
            class="tw-w-4 tw-h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill="#fff"
          >
            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"
            />
          </svg>
        </button>
      </div>
    </div>
    <div class="tw-text-gray-400 tw-text-sm tw-absolute tw-right-2 tw-top-1">
      {{ lastAlertDisplayTimestamp }}
    </div>
    <button
      type="button"
      class="tw tw-absolute tw-bottom-0 tw-left-1/2 tw-transform tw--translate-x-1/2 tw-text-gray-500"
      @click.prevent="expand = !expand"
    >
      <p v-if="expand">ย่อ</p>
      <p v-else>เพิ่มเติม...</p>
    </button>
  </div>
  <!-- <div
    @click="onClick"
    class="button is-flex is-flex-direction-column"
  >
    <b-image
      :src="type === 'SOOB' ? iconSoob : icon"
      alt="A random image"
    ></b-image>
    <div class="detail">
      <p class="tw-text-lg tw-text-primary tw-my-1">
        {{ device.name }}
      </p>
      <div :class="`is-flex`">
        <p>{{ $t("Connection Status") }} :</p>
        <p :class="connected | connectivityStyle">
          {{ connected | connectivity | t }}
        </p>
      </div>
    </div>
    <div class="is-flex is-align-items-baseline">
      <p>{{ $t("Alert Status") }}:</p>
      <template v-if="lastAlert">
        <b class="mx-1 has-text-danger"
          >{{ lastAlert.field }} {{ lastAlertValue }}</b
        >
      </template>
    </div>
    <div class="has-text-grey is-size-7 list-alert-text">
      {{ lastAlertDisplayTimestamp }}
    </div>
  </div> -->
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
import { mapGetters } from "vuex";
import { registerPostUpdate } from "echarts";
export default {
  name: "DeviceCard",
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      connected: false,
      lastAlert: null,
      icon: require("@/assets/v2/logo/watercare.png"),
      iconSoob: require("@/assets/v2/logo/watercare_soob.png"),
      type: null,
      expand: false,
      editMode: false,
      config: null,
      pondId: null,
      pondList: [],
      fieldData: [],
      newName: "",
    };
  },
  computed: {
    ...mapGetters({
      messageByTopic: "mq/messageByTopic",
      messageCounter: "mq/messageCounter",
      connectivityCounter: "mq/connectivityCounter",
    }),
    dropdown() {
      return this.$t("Actions");
    },
    connectivityTopic() {
      return this.device.thingID + "/connected";
    },
    alertStreamCounter() {
      return this.$store.state.alertStream.counter;
    },
    lastAlertValue() {
      return this.lastAlert.value.toFixed(2);
    },
    // lastAlert() {
    //     if (
    //         this.$store.state.alertStream.alertStream[this.device.thingID]
    //     ) {
    //         if (
    //             this.$store.state.alertStream.alertStream[
    //                 this.device.thingID
    //             ].length > 0
    //         ) {
    //             return this.$store.state.alertStream.alertStream[
    //                 this.device.thingID
    //             ][0];
    //         }
    //     }
    //     return null;
    // },
    lastAlertDisplayTimestamp() {
      if (this.lastAlert) {
        const timestamp = dayjs(this.lastAlert.createdAt);
        console.log({ timestamp });
        return timestamp.fromNow();
      }
      return null;
    },
  },
  watch: {
    connectivityCounter() {
      this.connected =
        this.$store.getters["mq/connectivity"](this.device.thingID) || false;
    },
    alertStreamCounter() {
      if (this.$store.state.alertStream.alertStream[this.device.thingID]) {
        if (
          this.$store.state.alertStream.alertStream[this.device.thingID]
            .length > 0
        ) {
          this.lastAlert = this.$store.state.alertStream.alertStream[
            this.device.thingID
          ][0];
        }
      }
    },
    "config.type": {
      immediate: true,
      handler: function(val) {
        switch (val) {
          case "SOOB":
            this.pondList = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];
            this.pondId = this.pondList[0].id;
            break;
          case "JOOM":
            this.pondList = [{ id: 1 }];
            this.pondId = this.pondList[0].id;
            break;

          default:
            this.pondId = null;
            break;
        }
      },
    },
    pondId: {
      immediate: true,
      handler: async function(id) {
        console.log("pond id changed to ", id);
        if (this.config?.field) {
          this.fieldData = await Promise.all(
            this.config?.field?.map(async (f) => {
              // console.log("map value", f);
              const result = await this.fetchData(f.name);
              console.log("fetch data result: ", result);
              return {
                ...f,
                currentValue: result?.toFixed(2) || "n/a",
              };
            })
          );
        }
      },
    },
  },
  filters: {
    connectivity: function(value) {
      if (value) return "connected";
      return "disconnected";
    },
    connectivityStyle: function(value) {
      if (value) return "mx-1 has-text-success";
      return "mx-1 disconnected has-text-danger";
    },
  },
  mounted() {
    this.$store.dispatch("mq/MqttSubscribe", this.connectivityTopic);
    this.connected =
      this.$store.getters["mq/connectivity"](this.device.thingID) || false;

    this.$store.dispatch("alertStream/getAlertStream", {
      thingID: this.device.thingID,
    });

    this.loadThingData(this.device.thingID);
  },
  methods: {
    onClick() {
      console.log("click");
      this.$emit("on-click", this.device);
    },
    async loadThingData(thingID) {
      try {
        const thing = await this.$service.getThing(thingID);
        console.log("loadThingData ", { thing });
        this.type = thing.type;
        this.checkType(this.type);
      } catch (error) {
        console.warn(error);
      }
    },
    checkType(type) {
      const d = this.$service.getDeviceUIComponent(type);
      this.component = d.component;
      this.config = d;
    },
    async fetchData(field) {
      // console.log("fetchData input", field);
      if (!this.device.thingID) {
        return;
      }
      try {
        let result;
        if (this.pondId != null || this.pondId != undefined) {
          result = await this.$store.dispatch("timeseries/getLatestStream", {
            thingID: this.device.thingID,
            field: this.pondId + "#" + field,
          });
        } else {
          result = await this.$store.dispatch("timeseries/getLatestStream", {
            thingID: this.device.thingID,
            field: field,
          });
        }

        console.log(result);
        return result.Point?.Value;
      } catch (error) {
        console.warn(error);
      }
    },
    async onSaveNewName() {
      const name = this.newName.trim();
      if (!name) {
        return;
      }
      console.log("new name: ", name);

      try {
        await this.$service.updateThingName(this.device.thingID, name);
        this.fetchUser();
      } catch (error) {
        console.error(error);
      }
      this.newName = null;
      this.editMode = false;
    },
    async fetchUser() {
      const user = this.$store.getters["auth/user"];
      const { username, attributes } = user;
      const { email } = attributes;
      const result = await this.$store.dispatch("user/fetchUser", {
        username,
        name: email,
      });
      console.log("fetchUser", result.things.items);

      result.things.items = await Promise.all(
        result.things.items.map(async (thing) => {
          const response = await this.$service.getThing(thing.thingID);
          return {
            ...thing,
            name: response.name,
          };
        })
      );

      await this.$store.commit("user/setUser", result);
    },
  },
};
</script>

<style>
/* * {
  border: 1px solid red !important;
} */
</style>
