<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ $t("Add rule type") }}</p>
    </header>
    <section class="modal-card-body" style="text-align: start">
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <b-step-item size="is-small" step="1">
          <div
            class="is-size-5 has-text-weight-semibold has-text-centered pb-3"
          >
            {{ "Choose device" | t }}
          </div>
          <b-field v-for="item in thingList" :key="item.thingID">
            <b-radio v-model="selected.thingID" :native-value="item.thingID">
              {{ item.name }}
            </b-radio>
          </b-field>
        </b-step-item>

        <b-step-item size="is-small" step="2">
          <div
            class="is-size-5 has-text-weight-semibold has-text-centered pb-3"
          >
            {{ "Choose field" | t }}
          </div>

          <b-field v-for="item in fieldList" :key="item">
            <b-radio v-model="selected.field" :native-value="item">
              {{ item }}
            </b-radio>
          </b-field>
        </b-step-item>

        <b-step-item size="is-small" step="3">
          <div
            class="is-size-5 has-text-weight-semibold has-text-centered pb-3"
          >
            {{ "Choose method" | t }}
          </div>

          <b-field>
            <b-radio v-model="selected.method" native-value="MAGNITUDE">
              {{ "MAGNITUDE" | t }}
            </b-radio>
          </b-field>

          <template v-if="selected.method === 'MAGNITUDE'">
            <div class="pb-3">
              {{ "MAGNITUDE_DESCRIPTION" | t }}
            </div>
          </template>

          <b-field>
            <b-radio v-model="selected.method" native-value="FENCE">
              {{ "FENCE" | t }}
            </b-radio>
          </b-field>

          <template v-if="selected.method === 'FENCE'">
            <div class="pb-3">
              {{ "FENCE_DESCRIPTION" | t }}
            </div>

            <b-field :label="`Upper bound` | t">
              <b-numberinput
                :step="0.1"
                :controls="false"
                v-model="selected.upperBound"
              >
              </b-numberinput>
            </b-field>
            <b-field :label="`Lower bound` | t">
              <b-numberinput
                :step="0.1"
                :controls="false"
                v-model="selected.lowerBound"
              >
              </b-numberinput>
            </b-field>
          </template>
        </b-step-item>

        <b-step-item size="is-small" step="4">
          <div
            class="is-size-5 has-text-weight-semibold has-text-centered pb-3"
          >
            {{ "Choose destinations" | t }}
          </div>

          <div v-for="item in alertList" :key="item.id">
            <b-checkbox v-model="selected.alerts" :native-value="item">
              {{ item.name }}
            </b-checkbox>
          </div>
        </b-step-item>

        <b-step-item size="is-small" step="5">
          <div
            class="is-size-5 has-text-weight-semibold has-text-centered pb-3"
          >
            {{ "Summary" | t }}
          </div>

          <div class="is-flex is-justify-content-space-between">
            <div>
              {{ "Method" | t }}
            </div>
            <div>
              {{ selected.method | t }}
            </div>
          </div>

          <div
            v-if="selected.method === 'FENCE'"
            class="is-flex is-justify-content-space-between"
          >
            <div>
              {{ "Upper bound" | t }}
            </div>
            <div>
              {{ selected.upperBound }}
            </div>
          </div>
          <div
            v-if="selected.method === 'FENCE'"
            class="is-flex is-justify-content-space-between"
          >
            <div>
              {{ "Lower bound" | t }}
            </div>
            <div>
              {{ selected.lowerBound }}
            </div>
          </div>

          <div class="is-flex is-justify-content-space-between">
            <div>
              {{ "Device" | t }}
            </div>
            <div>
              {{ selected.thingID }}
            </div>
          </div>

          <div class="is-flex is-justify-content-space-between">
            <div>
              {{ "Field" | t }}
            </div>
            <div>
              {{ selected.field }}
            </div>
          </div>

          <div
            class="is-flex is-justify-content-space-between mt-2 pt-1"
            style="border-top: 0.1px solid #e0e0e0"
          >
            <div>
              {{ "Destinations" | t }}
            </div>
            <div>
              <div v-for="item in selected.alerts" :key="item.id">
                {{ item.name }}
              </div>
            </div>
          </div>
        </b-step-item>

        <template #navigation="{ previous, next }">
          <b-button
            class="mr-1"
            outlined
            icon-left="arrow-left"
            :disabled="previous.disabled"
            @click.prevent="previous.action"
          >
            {{ "Previous" | t }}
          </b-button>
          <b-button
            outlined
            icon-right="arrow-right"
            :disabled="next.disabled"
            @click.prevent="next.action"
          >
            {{ "Next" | t }}
          </b-button>
        </template>
      </b-steps>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <b-button :label="`Cancel` | t" @click="$parent.close()" />
      <b-button
        :label="`Confirm` | t"
        type="is-primary"
        :disabled="!canConfirm"
        @click="onAddRule"
      />
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AddNotifyTypeForm",
  data() {
    return {
      activeStep: 0,

      showSocial: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,

      hasNavigation: true,
      customNavigation: false,
      isProfileSuccess: false,

      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      labelPosition: "bottom",
      mobileMode: null,
      selected: {
        id: null,
        thingID: null,
        AnomalyDetectorID: null,
        topic: "data",
        field: null,
        enable: true,
        method: null,
        sensitivity: 0.1, //0-1
        upperBound: 10,
        lowerBound: 0,
        threshold: 0.85,
        alerts: [],
      },
      fieldList: ["DO", "pH", "EC", "TempAir", "TempWater"],
      methodList: ["FENCE", "MAGNITUDE"],
    };
  },
  computed: {
    ...mapGetters({
      thingList: "user/thingList",
      alertList: "user/alertList",
      isConnected: "mq/isConnected",
      ruleList: "anomalyDetectorTask/ruleList",
    }),
    canConfirm() {
      if (this.selected.method != null) {
        if (this.selected.thingID != null) {
          if (this.selected.field != null) {
            if (this.activeStep == 4) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  methods: {
    async onAddRule() {
      // if (
      //     [null, undefined, ""].indexOf(
      //         this.anomalyDetectorTask.thingID
      //     ) >= 0
      // ) {
      //     return;
      // }
      // if (
      //     [null, undefined, ""].indexOf(this.anomalyDetectorTask.topic) >=
      //     0
      // ) {
      //     return;
      // }
      // if (
      //     [null, undefined, ""].indexOf(this.anomalyDetectorTask.field) >=
      //     0
      // ) {
      //     return;
      // }
      // if (
      //     [null, undefined, ""].indexOf(
      //         this.anomalyDetectorTask.method
      //     ) >= 0
      // ) {
      //     return;
      // }
      // if (
      //     [null, undefined, ""].indexOf(
      //         this.anomalyDetectorTask.upperBound
      //     ) >= 0
      // ) {
      //     return;
      // }
      // if (
      //     [null, undefined, ""].indexOf(
      //         this.anomalyDetectorTask.lowerBound
      //     ) >= 0
      // ) {
      //     return;
      // }
      // if (
      //     [null, undefined, ""].indexOf(
      //         this.anomalyDetectorTask.threshold
      //     ) >= 0
      // ) {
      //     return;
      // }
      // if (this.anomalyDetectorTask.alerts.length <= 0) {
      //     return;
      // }
      console.log("add rule");
      // this.anomalyDetectorTask.sensitivity =
      //     this.anomalyDetectorTask.sensitivity / 100;
      // this.anomalyDetectorTask.threshold =
      //     this.anomalyDetectorTask.threshold / 100;
      this.$store.dispatch("anomalyDetectorTask/addRule", this.selected);
      this.$parent.close();
    },
  },
};
</script>
