<template>
  <div class="is-clickable is-primary">
    <div class="main-content main-card has-background-primary">
      <div
        class="
          is-size-6
          has-text-white
          is-align-self-flex-start
          ml-4
          mt-2
          header-state
        "
      >
        <div class="name">{{ $t("Aerator status") }}</div>
        <!-- <div class="name">mode: {{ aeratorCurrentMode }}</div> -->
      </div>
      <div style="flex: 2; display: grid; margin: auto">
        <div class="is-size-4 has-text-white">{{ $t("Status") }}</div>
        <div class="is-size-1 has-text-white">
          <!-- {{ $t(currentState) }} -->
          {{ $t(aeratorStatus) }}
        </div>
      </div>

      <div class="level is-mobile mb-2 mx-2">
        <div class="level-item">
          <b-button
            :loading="openLoading"
            @click="onOn"
            :type="`${
              PondAeraModeGetAccepted == 1 ? 'is-success' : 'is-light'
            }`"
            expanded
            size="is-medium"
            >{{ $t("On") }}</b-button
          >
        </div>
        <div class="level-item">
          <b-button
            :loading="offLoading"
            @click="onOff"
            :type="`${
              PondAeraModeGetAccepted == 0 ? 'is-success' : 'is-light'
            }`"
            expanded
            size="is-medium"
            >{{ $t("Off") }}</b-button
          >
        </div>
        <div class="level-item">
          <b-button
            :loading="autoLoading"
            @click="onAuto"
            :type="`${
              PondAeraModeGetAccepted == 2 ? 'is-success' : 'is-light'
            }`"
            expanded
            size="is-medium"
            >{{ $t("Auto") }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { debounce } from "debounce";
import { mapGetters } from "vuex";
// const TOPIC = "SOOB";
const LOADING_TIME = 10000;
let onTimeout;
let offTimeout;
let autoTimeout;

export default {
  name: "DeviceControlCardSOOB",
  props: ["thingID", "id", "mqttTopic"],
  data() {
    return {
      openLoading: false,
      offLoading: false,
      autoLoading: false,
      PondAeraStateGetAccepted: null,
      PondAeraStateUpdateAccepted: null,
      aeratorStatus: "n/a",
      PondAeraModeGetAccepted: null,
      PondAeraModeUpdateAccepted: null,
      aeratorCurrentMode: "n/a",
      updateAccepted: null,
    };
  },
  computed: {
    ...mapGetters({
      messageCounter: "mq/messageCounter",
    }),
  },
  async mounted() {
    //Subscribe
    await this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/${this.mqttTopic}/update/accepted`
    );
    await this.$store.dispatch(
      "mq/MqttSubscribe",
      `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
    );

    //Publish
    await this.$store.dispatch("mq/MqttPublish", {
      topic: `${this.thingID}/shadow/${this.mqttTopic}/get`,
      payload: "0",
    });
  },
  watch: {
    messageCounter: {
      handler: async function () {
        this.updateAccepted = await this.$store.getters["mq/messageByTopic"](
          `${this.thingID}/shadow/${this.mqttTopic}/update/accepted`
        );

        switch (this.id) {
          case 1:
            this.PondAeraStateGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraState00;

            // this.PondAeraStateUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraState00;

            this.PondAeraModeGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraMode00;

            // this.PondAeraModeUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraMode00;
            break;

          case 2:
            this.PondAeraStateGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraState01;

            // this.PondAeraStateUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraState01;

            this.PondAeraModeGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraMode01;

            // this.PondAeraModeUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraMode01;
            break;

          case 3:
            this.PondAeraStateGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraState02;

            // this.PondAeraStateUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraState02;

            this.PondAeraModeGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraMode02;

            // this.PondAeraModeUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraMode02;
            break;

          case 4:
            this.PondAeraStateGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraState03;

            // this.PondAeraStateUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraState03;

            this.PondAeraModeGetAccepted = this.$store.getters[
              "mq/messageByTopic"
            ](
              `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
            )?.reported?.PondAeraMode03;

            // this.PondAeraModeUpdateAccepted = this.$store.getters[
            //   "mq/messageByTopic"
            // ](
            //   `${this.thingID}/shadow/${TOPIC}/update/delta/json`
            // )?.reported?.PondAeraMode03;
            break;

          default:
            this.PondAeraStateGetAccepted = null;
            // this.PondAeraStateUpdateAccepted = null;
            this.PondAeraModeGetAccepted = null;

            break;
        }
      },
    },
    PondAeraStateGetAccepted: {
      handler: function (value) {
        console.log("PondAeraStateGetAccepted update!", value);
        if (value !== undefined) {
          switch (value) {
            case 0:
              this.aeratorStatus = "Off";
              break;
            case 1:
              this.aeratorStatus = "On";
              break;
            // case 2:
            //   this.aeratorStatus = "Auto";
            //   break;
            default:
              this.aeratorStatus = "n/a";
          }
        }
      },
    },
    PondAeraStateUpdateAccepted: {
      handler: function (value) {
        console.log("PondAeraStateUpdateAccepted update!", value);
        if (value !== undefined) {
          switch (value) {
            case 0:
              this.aeratorStatus = "Off";
              break;
            case 1:
              this.aeratorStatus = "On";
              break;
            // case 2:
            //   this.aeratorStatus = "Auto";
            //   break;
            default:
              this.aeratorStatus = "n/a";
          }
        }
      },
    },
    PondAeraModeGetAccepted: {
      handler: function (value) {
        console.log("PondAeraModeGetAccepted update!", value);
        if (value !== undefined) {
          switch (value) {
            case 0:
              this.aeratorCurrentMode = "Manual Off";
              clearTimeout(this.offTimeout);
              this.offLoading = false;
              break;
            case 1:
              this.aeratorCurrentMode = "Manual On";
              clearTimeout(this.onTimeout);
              this.openLoading = false;
              break;
            case 2:
              this.aeratorCurrentMode = "Auto";
              clearTimeout(this.autoTimeout);
              this.autoLoading = false;
              break;
            default:
              this.aeratorCurrentMode = "n/a";
          }
        }
      },
    },
    PondAeraModeUpdateAccepted: {
      handler: function (value) {
        console.log("PondAeraModeUpdateAccepted update!", value);
        if (value !== undefined) {
          switch (value) {
            case 0:
              this.aeratorCurrentMode = "Manual Off";
              break;
            case 1:
              this.aeratorCurrentMode = "Manual On";
              break;
            case 2:
              this.aeratorCurrentMode = "Auto";
              break;
            default:
              this.aeratorCurrentMode = "n/a";
          }
        }
      },
    },
    "updateAccepted.timestamp": {
      handler: async function () {
        console.log("updateAccepted value: ", this.updateAccepted);
        this.getNewData();
      },
    },
  },
  methods: {
    onOn: debounce(async function () {
      console.log("onOn");
      this.openLoading = true;

      let payload;
      // await this.$store.dispatch("mq/MqttPublish", {
      //   topic: `${this.thingID}/shadow/${TOPIC}/get`,
      //   payload: "0",
      // });

      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
      );
      payload = getMsg;

      switch (this.id) {
        case 1:
          payload.desired.PondAeraMode00 = 1;
          break;
        case 2:
          payload.desired.PondAeraMode01 = 1;
          break;
        case 3:
          payload.desired.PondAeraMode02 = 1;
          break;
        case 4:
          payload.desired.PondAeraMode03 = 1;
          break;
        default:
          break;
      }

      console.log("onOn", payload);

      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.mqttTopic}/update`,
        payload: JSON.stringify(payload),
      });

      this.onTimeout = setTimeout(() => {
        this.openLoading = false;
      }, LOADING_TIME);
    }, 300),

    onOff: debounce(async function () {
      console.log("onOff");
      this.offLoading = true;

      let payload;
      // await this.$store.dispatch("mq/MqttPublish", {
      //   topic: `${this.thingID}/shadow/${TOPIC}/get`,
      //   payload: "0",
      // });

      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
      );
      payload = getMsg;

      switch (this.id) {
        case 1:
          payload.desired.PondAeraMode00 = 0;
          break;
        case 2:
          payload.desired.PondAeraMode01 = 0;
          break;
        case 3:
          payload.desired.PondAeraMode02 = 0;
          break;
        case 4:
          payload.desired.PondAeraMode03 = 0;
          break;
        default:
          break;
      }

      console.log("onOff", payload);

      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.mqttTopic}/update`,
        payload: JSON.stringify(payload),
      });

      this.offTimeout = setTimeout(() => {
        this.offLoading = false;
      }, LOADING_TIME);
    }, 300),

    onAuto: debounce(async function () {
      console.log("onAuto");
      this.autoLoading = true;

      let payload;
      // await this.$store.dispatch("mq/MqttPublish", {
      //   topic: `${this.thingID}/shadow/${TOPIC}/get`,
      //   payload: "0",
      // });

      const getMsg = await this.$store.getters["mq/messageByTopic"](
        `${this.thingID}/shadow/${this.mqttTopic}/get/accepted`
      );
      payload = getMsg;

      switch (this.id) {
        case 1:
          payload.desired.PondAeraMode00 = 2;
          break;
        case 2:
          payload.desired.PondAeraMode01 = 2;
          break;
        case 3:
          payload.desired.PondAeraMode02 = 2;
          break;
        case 4:
          payload.desired.PondAeraMode03 = 2;
          break;
        default:
          break;
      }
      console.log("onOff", payload);

      this.$store.dispatch("mq/MqttPublish", {
        topic: `${this.thingID}/shadow/${this.mqttTopic}/update`,
        payload: JSON.stringify(payload),
      });

      this.autoTimeout = setTimeout(() => {
        this.autoLoading = false;
      }, LOADING_TIME);
    }, 300),

    getNewData: debounce(async function () {
      if (this.updateAccepted?.code === 200) {
        console.log("Get new value");
        await this.$store.dispatch("mq/MqttPublish", {
          topic: `${this.thingID}/shadow/${this.mqttTopic}/get`,
          payload: "0",
        });
      }
    }, 500),
  },
};
</script>

<style scoped>
.main-card {
  overflow: hidden;
  border-radius: 15px;
}
.main-content {
  position: relative;
  display: flex;
  flex-direction: column;
  /* aspect-ratio: 3; */
  height: 200px;
  /* border: 1px solid #1c75e0; */
}
.main-title {
  /* position: absolute; */
  font-size: 13.33px;
}
.main-value-title {
  font-size: 0.3em;
  /* align-self: center; */
}
.main-last-update-display {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 11px;
}
.header-table {
  border: 1px solid green;
}
.header-state {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  width: -webkit-fill-available;
  justify-content: space-between;
}
</style>
