<template>
  <div>
    <div
      class="
        nav-bar
        has-background-white
        is-flex is-justify-content-space-between
        px-3
        py-2
      "
    >
      <div class="dummy">
        <b-button
          v-if="showPreviousButton"
          size="is-small"
          type="is-info is-light"
          icon-left="arrow-left"
          @click="onPrevious"
        >
          {{ $t("previous") }}
        </b-button>
      </div>
      <div>
        <span v-if="device" class="thing-id-text">{{ device.name }}</span>
        <b-button
          v-if="component == 'SOOB_V1' || component == 'JOOM_V1'"
          @click="onOpenSettingsDialog"
          icon-right="cog"
          size="is-small is-ghost"
        ></b-button>
      </div>
      <div class="dummy">
        <b-button
          v-if="showNextButton"
          size="is-small"
          type="is-info is-light"
          icon-right="arrow-right"
          @click="onNext"
        >
          {{ $t("next") }}
        </b-button>
      </div>
    </div>

    <!-- <template v-if="!loading">
            <div
                class="relative columns m-0 p-0 is-multiline"
                style="min-height: 300px border-red"
            >
                <b-loading :is-full-page="false" v-model="loading"></b-loading>
                <div>Hello</div>
            </div>
        </template>

        <template v-else> -->
    <component :is="component" :config="config" />
    <!-- </template> -->
    <b-modal
      v-model="isShowSetting"
      has-modal-card
      full-screen
      :can-cancel="false"
    >
      <overall-setting
        v-if="config"
        :thingID="thingID"
        :name="''"
        :MQTT_TOPIC="`${component == 'SOOB_V1' ? 'SOOB' : 'JOOM'}`"
        :features="config.features"
        @changed-name="onChangedName"
      ></overall-setting>
    </b-modal>
  </div>
</template>

<script>
// import DeviceFieldCard from "@/components/DeviceFieldCard.vue";
// import DeviceControlCard from "@/components/DeviceControlCard.vue";
import JOOM_V1 from "@/components/devices/JOOM_V1/JOOM_V1.vue";
import LOOKGOONG from "@/components/devices/LOOKGOONG/LOOKGOONG.vue";
import SOOB_V1 from "@/components/devices/SOOB_V1/SOOB_V1.vue";
import OverallSetting from "@/components/OverallSetting.vue";
export default {
  name: "ProfileDeivce",
  components: {
    JOOM_V1,
    SOOB_V1,
    LOOKGOONG,
    OverallSetting,
    // DeviceFieldCard,
    // DeviceControlCard
  },
  data() {
    return {
      device: null,
      isShowSetting: false,
      loading: true,
      type: null,
      component: null,
      config: null,
      sensorList: [
        {
          id: "1",
          name: "DO",
          unit: "mg/L",
        },
        {
          id: "2",
          name: "pH",
          unit: "",
        },
        {
          id: "3",
          name: "TempWater",
          unit: "°C",
        },
        {
          id: "4",
          name: "TempAir",
          unit: "°C",
        },
        {
          id: "5",
          name: "Humidity",
          unit: "%",
        },
        // {
        //     id: "4",
        //     name: "EC",
        //     unit: "ms/cm"
        // },
      ],
    };
  },
  computed: {
    thingID() {
      return this.$route.params["thingid"];
    },
    showPreviousButton() {
      const index = this.$store.getters["user/thingList"].findIndex(
        (i) => i.thingID === this.thingID
      );
      return index > 0;
    },
    showNextButton() {
      const index = this.$store.getters["user/thingList"].findIndex(
        (i) => i.thingID === this.thingID
      );
      return index < this.$store.getters["user/thingList"].length - 1;
    },
  },
  watch: {
    thingID: {
      handler: function(value) {
        this.loadThingData(value);
        this.onChangedName();
      },
    },
  },
  updated() {
    console.log("path updated!");
  },
  async mounted() {
    this.loadThingData(this.thingID);
    this.onChangedName();
  },
  methods: {
    async loadThingData(thingID) {
      try {
        this.loading = true;
        const thing = await this.$service.getThing(thingID);
        console.log({ thing });
        this.type = thing.type;
        this.checkType(this.type);
        this.loading = false;
      } catch (error) {
        console.warn(error);
      }
    },
    checkType(type) {
      const d = this.$service.getDeviceUIComponent(type);
      this.component = d.component;
      this.config = d;
    },
    onPrevious() {
      console.log("onPrevious");
      const index = this.$store.getters["user/thingList"].findIndex(
        (i) => i.thingID === this.thingID
      );

      const previousThingID = this.$store.getters["user/thingList"][index - 1]
        .thingID;

      this.$router.replace({
        name: "ProfileDevice",
        params: {
          thingid: previousThingID,
        },
      });
    },
    async onChangedName() {
      this.device = await this.$service.getThing(this.thingID);
    },
    onNext() {
      console.log("onNext");
      const index = this.$store.getters["user/thingList"].findIndex(
        (i) => i.thingID === this.thingID
      );

      const nextThingID = this.$store.getters["user/thingList"][index + 1]
        .thingID;

      this.$router.replace({
        name: "ProfileDevice",
        params: {
          thingid: nextThingID,
        },
      });
    },
    async onOpenSettingsDialog() {
      console.log("open settings dialog", this.thingID);
      this.isShowSetting = true;
    },
    close() {
      this.isShowSetting = false;
    },
  },
};
</script>

<style scoped>
.nav-bar {
  position: sticky;
  top: 52px;
  z-index: 1;
}
.dummy {
  min-width: 64px;
}
.thing-id-text {
  height: 30px;
}
</style>
