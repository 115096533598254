/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateThingStreamByTopic = /* GraphQL */ `
  subscription OnCreateThingStreamByTopic($topic: ID) {
    onCreateThingStreamByTopic(topic: $topic) {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingTimeseriesByThingIdAndFieldName = /* GraphQL */ `
  subscription OnCreateThingTimeseriesByThingIdAndFieldName(
    $thingID: ID
    $fieldName: String
  ) {
    onCreateThingTimeseriesByThingIDAndFieldName(
      thingID: $thingID
      fieldName: $fieldName
    ) {
      thingID
      timestamp
      fieldName
      mean
      count
      min
      max
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSystemsMessageByUserId = /* GraphQL */ `
  subscription OnCreateSystemsMessageByUserId($userID: ID) {
    onCreateSystemsMessageByUserID(userID: $userID) {
      id
      createdAt
      type
      title
      userID
      message
      status
      options
      updatedAt
    }
  }
`;
export const onUpdateUserThingByUserId = /* GraphQL */ `
  subscription OnUpdateUserThingByUserId($userID: ID) {
    onUpdateUserThingByUserID(userID: $userID) {
      id
      userID
      thingID
      role
      allowCSV
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      name
      language
      weatherLocation {
        displayName
        lat
        lon
      }
      things {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      thingGroups {
        items {
          id
          userID
          thingGroupID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          name
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      name
      language
      weatherLocation {
        displayName
        lat
        lon
      }
      things {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      thingGroups {
        items {
          id
          userID
          thingGroupID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          name
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      name
      language
      weatherLocation {
        displayName
        lat
        lon
      }
      things {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      thingGroups {
        items {
          id
          userID
          thingGroupID
          role
          createdAt
          updatedAt
        }
        nextToken
      }
      dashboards {
        items {
          id
          name
          themeColor
          userID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      rules {
        items {
          id
          name
          type
          input
          rule
          parameter
          description
          running
          userID
          output
          createdAt
          updatedAt
        }
        nextToken
      }
      alerts {
        items {
          id
          userID
          type
          name
          token
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserThing = /* GraphQL */ `
  subscription OnCreateUserThing {
    onCreateUserThing {
      id
      userID
      thingID
      role
      allowCSV
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserThing = /* GraphQL */ `
  subscription OnUpdateUserThing {
    onUpdateUserThing {
      id
      userID
      thingID
      role
      allowCSV
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserThing = /* GraphQL */ `
  subscription OnDeleteUserThing {
    onDeleteUserThing {
      id
      userID
      thingID
      role
      allowCSV
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThing = /* GraphQL */ `
  subscription OnCreateThing {
    onCreateThing {
      thingID
      name
      expiredDate
      type
      serial
      description
      tags
      other
      users {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      console {
        thingID
        name
        createdAt
        updatedAt
      }
      aggregates {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      anomalyDetectors {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      credentials {
        items {
          username
          password
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThing = /* GraphQL */ `
  subscription OnUpdateThing {
    onUpdateThing {
      thingID
      name
      expiredDate
      type
      serial
      description
      tags
      other
      users {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      console {
        thingID
        name
        createdAt
        updatedAt
      }
      aggregates {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      anomalyDetectors {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      credentials {
        items {
          username
          password
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThing = /* GraphQL */ `
  subscription OnDeleteThing {
    onDeleteThing {
      thingID
      name
      expiredDate
      type
      serial
      description
      tags
      other
      users {
        items {
          id
          userID
          thingID
          role
          allowCSV
          createdAt
          updatedAt
        }
        nextToken
      }
      console {
        thingID
        name
        createdAt
        updatedAt
      }
      aggregates {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      anomalyDetectors {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      credentials {
        items {
          username
          password
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingStream = /* GraphQL */ `
  subscription OnCreateThingStream {
    onCreateThingStream {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingStream = /* GraphQL */ `
  subscription OnUpdateThingStream {
    onUpdateThingStream {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingStream = /* GraphQL */ `
  subscription OnDeleteThingStream {
    onDeleteThingStream {
      topic
      timestamp
      thingID
      fieldName
      mean
      count
      min
      max
      AggregatorID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingShadow = /* GraphQL */ `
  subscription OnCreateThingShadow {
    onCreateThingShadow {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      packetID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingShadow = /* GraphQL */ `
  subscription OnUpdateThingShadow {
    onUpdateThingShadow {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      packetID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingShadow = /* GraphQL */ `
  subscription OnDeleteThingShadow {
    onDeleteThingShadow {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      packetID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingShadowHistory = /* GraphQL */ `
  subscription OnCreateThingShadowHistory {
    onCreateThingShadowHistory {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      action
      packetID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingShadowHistory = /* GraphQL */ `
  subscription OnUpdateThingShadowHistory {
    onUpdateThingShadowHistory {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      action
      packetID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingShadowHistory = /* GraphQL */ `
  subscription OnDeleteThingShadowHistory {
    onDeleteThingShadowHistory {
      thingID
      shadowID
      timestamp
      desired
      reported
      delta
      metadata
      action
      packetID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingCredentials = /* GraphQL */ `
  subscription OnCreateThingCredentials {
    onCreateThingCredentials {
      username
      password
      thingID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingCredentials = /* GraphQL */ `
  subscription OnUpdateThingCredentials {
    onUpdateThingCredentials {
      username
      password
      thingID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingCredentials = /* GraphQL */ `
  subscription OnDeleteThingCredentials {
    onDeleteThingCredentials {
      username
      password
      thingID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingConsole = /* GraphQL */ `
  subscription OnCreateThingConsole {
    onCreateThingConsole {
      thingID
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingConsole = /* GraphQL */ `
  subscription OnUpdateThingConsole {
    onUpdateThingConsole {
      thingID
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingConsole = /* GraphQL */ `
  subscription OnDeleteThingConsole {
    onDeleteThingConsole {
      thingID
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAggregator = /* GraphQL */ `
  subscription OnCreateAggregator {
    onCreateAggregator {
      id
      tasks {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAggregator = /* GraphQL */ `
  subscription OnUpdateAggregator {
    onUpdateAggregator {
      id
      tasks {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAggregator = /* GraphQL */ `
  subscription OnDeleteAggregator {
    onDeleteAggregator {
      id
      tasks {
        items {
          id
          thingID
          AggregatorID
          enable
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAggregateTask = /* GraphQL */ `
  subscription OnCreateAggregateTask {
    onCreateAggregateTask {
      id
      thingID
      AggregatorID
      enable
      topics {
        items {
          id
          AggregateTaskID
          topic
          createdAt
          updatedAt
        }
        nextToken
      }
      detectedFields {
        items {
          id
          AggregateTaskID
          field
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAggregateTask = /* GraphQL */ `
  subscription OnUpdateAggregateTask {
    onUpdateAggregateTask {
      id
      thingID
      AggregatorID
      enable
      topics {
        items {
          id
          AggregateTaskID
          topic
          createdAt
          updatedAt
        }
        nextToken
      }
      detectedFields {
        items {
          id
          AggregateTaskID
          field
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAggregateTask = /* GraphQL */ `
  subscription OnDeleteAggregateTask {
    onDeleteAggregateTask {
      id
      thingID
      AggregatorID
      enable
      topics {
        items {
          id
          AggregateTaskID
          topic
          createdAt
          updatedAt
        }
        nextToken
      }
      detectedFields {
        items {
          id
          AggregateTaskID
          field
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAggregateTaskTopic = /* GraphQL */ `
  subscription OnCreateAggregateTaskTopic {
    onCreateAggregateTaskTopic {
      id
      AggregateTaskID
      topic
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAggregateTaskTopic = /* GraphQL */ `
  subscription OnUpdateAggregateTaskTopic {
    onUpdateAggregateTaskTopic {
      id
      AggregateTaskID
      topic
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAggregateTaskTopic = /* GraphQL */ `
  subscription OnDeleteAggregateTaskTopic {
    onDeleteAggregateTaskTopic {
      id
      AggregateTaskID
      topic
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAggregateTaskDetectedField = /* GraphQL */ `
  subscription OnCreateAggregateTaskDetectedField {
    onCreateAggregateTaskDetectedField {
      id
      AggregateTaskID
      field
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAggregateTaskDetectedField = /* GraphQL */ `
  subscription OnUpdateAggregateTaskDetectedField {
    onUpdateAggregateTaskDetectedField {
      id
      AggregateTaskID
      field
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAggregateTaskDetectedField = /* GraphQL */ `
  subscription OnDeleteAggregateTaskDetectedField {
    onDeleteAggregateTaskDetectedField {
      id
      AggregateTaskID
      field
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnomalyDetector = /* GraphQL */ `
  subscription OnCreateAnomalyDetector {
    onCreateAnomalyDetector {
      id
      tasks {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnomalyDetector = /* GraphQL */ `
  subscription OnUpdateAnomalyDetector {
    onUpdateAnomalyDetector {
      id
      tasks {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnomalyDetector = /* GraphQL */ `
  subscription OnDeleteAnomalyDetector {
    onDeleteAnomalyDetector {
      id
      tasks {
        items {
          id
          thingID
          AnomalyDetectorID
          topic
          field
          enable
          method
          sensitivity
          upperBound
          lowerBound
          threshold
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnomalyDetectorTask = /* GraphQL */ `
  subscription OnCreateAnomalyDetectorTask {
    onCreateAnomalyDetectorTask {
      id
      thingID
      AnomalyDetectorID
      topic
      field
      enable
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnomalyDetectorTask = /* GraphQL */ `
  subscription OnUpdateAnomalyDetectorTask {
    onUpdateAnomalyDetectorTask {
      id
      thingID
      AnomalyDetectorID
      topic
      field
      enable
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnomalyDetectorTask = /* GraphQL */ `
  subscription OnDeleteAnomalyDetectorTask {
    onDeleteAnomalyDetectorTask {
      id
      thingID
      AnomalyDetectorID
      topic
      field
      enable
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlertStream = /* GraphQL */ `
  subscription OnCreateAlertStream {
    onCreateAlertStream {
      id
      type
      creatorID
      thingID
      topic
      field
      value
      userID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlertStream = /* GraphQL */ `
  subscription OnUpdateAlertStream {
    onUpdateAlertStream {
      id
      type
      creatorID
      thingID
      topic
      field
      value
      userID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlertStream = /* GraphQL */ `
  subscription OnDeleteAlertStream {
    onDeleteAlertStream {
      id
      type
      creatorID
      thingID
      topic
      field
      value
      userID
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnomalyDetectorTaskAlert = /* GraphQL */ `
  subscription OnCreateAnomalyDetectorTaskAlert {
    onCreateAnomalyDetectorTaskAlert {
      id
      taskID
      task {
        id
        thingID
        AnomalyDetectorID
        topic
        field
        enable
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnomalyDetectorTaskAlert = /* GraphQL */ `
  subscription OnUpdateAnomalyDetectorTaskAlert {
    onUpdateAnomalyDetectorTaskAlert {
      id
      taskID
      task {
        id
        thingID
        AnomalyDetectorID
        topic
        field
        enable
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnomalyDetectorTaskAlert = /* GraphQL */ `
  subscription OnDeleteAnomalyDetectorTaskAlert {
    onDeleteAnomalyDetectorTaskAlert {
      id
      taskID
      task {
        id
        thingID
        AnomalyDetectorID
        topic
        field
        enable
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        alerts {
          nextToken
        }
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlert = /* GraphQL */ `
  subscription OnCreateAlert {
    onCreateAlert {
      id
      userID
      type
      name
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlert = /* GraphQL */ `
  subscription OnUpdateAlert {
    onUpdateAlert {
      id
      userID
      type
      name
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlert = /* GraphQL */ `
  subscription OnDeleteAlert {
    onDeleteAlert {
      id
      userID
      type
      name
      token
      enable
      tasks {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateShadowDispatcher = /* GraphQL */ `
  subscription OnCreateShadowDispatcher {
    onCreateShadowDispatcher {
      id
      things {
        items {
          id
          thingID
          ShadowDispatcherID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateShadowDispatcher = /* GraphQL */ `
  subscription OnUpdateShadowDispatcher {
    onUpdateShadowDispatcher {
      id
      things {
        items {
          id
          thingID
          ShadowDispatcherID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteShadowDispatcher = /* GraphQL */ `
  subscription OnDeleteShadowDispatcher {
    onDeleteShadowDispatcher {
      id
      things {
        items {
          id
          thingID
          ShadowDispatcherID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateShadowDispatcherThing = /* GraphQL */ `
  subscription OnCreateShadowDispatcherThing {
    onCreateShadowDispatcherThing {
      id
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      ShadowDispatcherID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateShadowDispatcherThing = /* GraphQL */ `
  subscription OnUpdateShadowDispatcherThing {
    onUpdateShadowDispatcherThing {
      id
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      ShadowDispatcherID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteShadowDispatcherThing = /* GraphQL */ `
  subscription OnDeleteShadowDispatcherThing {
    onDeleteShadowDispatcherThing {
      id
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      ShadowDispatcherID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDashboard = /* GraphQL */ `
  subscription OnCreateDashboard {
    onCreateDashboard {
      id
      name
      themeColor
      dashboardItems {
        items {
          id
          name
          x
          y
          width
          height
          type
          description
          option
          timeSeriesSource
          realtimeSource
          dashboardID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      type
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDashboard = /* GraphQL */ `
  subscription OnUpdateDashboard {
    onUpdateDashboard {
      id
      name
      themeColor
      dashboardItems {
        items {
          id
          name
          x
          y
          width
          height
          type
          description
          option
          timeSeriesSource
          realtimeSource
          dashboardID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      type
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDashboard = /* GraphQL */ `
  subscription OnDeleteDashboard {
    onDeleteDashboard {
      id
      name
      themeColor
      dashboardItems {
        items {
          id
          name
          x
          y
          width
          height
          type
          description
          option
          timeSeriesSource
          realtimeSource
          dashboardID
          createdAt
          updatedAt
        }
        nextToken
      }
      userID
      type
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDashboardItem = /* GraphQL */ `
  subscription OnCreateDashboardItem {
    onCreateDashboardItem {
      id
      name
      x
      y
      width
      height
      type
      description
      option
      timeSeriesSource
      realtimeSource
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDashboardItem = /* GraphQL */ `
  subscription OnUpdateDashboardItem {
    onUpdateDashboardItem {
      id
      name
      x
      y
      width
      height
      type
      description
      option
      timeSeriesSource
      realtimeSource
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDashboardItem = /* GraphQL */ `
  subscription OnDeleteDashboardItem {
    onDeleteDashboardItem {
      id
      name
      x
      y
      width
      height
      type
      description
      option
      timeSeriesSource
      realtimeSource
      dashboardID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRule = /* GraphQL */ `
  subscription OnCreateRule {
    onCreateRule {
      id
      name
      type
      input
      rule
      parameter
      description
      running
      userID
      output
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRule = /* GraphQL */ `
  subscription OnUpdateRule {
    onUpdateRule {
      id
      name
      type
      input
      rule
      parameter
      description
      running
      userID
      output
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRule = /* GraphQL */ `
  subscription OnDeleteRule {
    onDeleteRule {
      id
      name
      type
      input
      rule
      parameter
      description
      running
      userID
      output
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSystemsMessage = /* GraphQL */ `
  subscription OnCreateSystemsMessage {
    onCreateSystemsMessage {
      id
      createdAt
      type
      title
      userID
      message
      status
      options
      updatedAt
    }
  }
`;
export const onUpdateSystemsMessage = /* GraphQL */ `
  subscription OnUpdateSystemsMessage {
    onUpdateSystemsMessage {
      id
      createdAt
      type
      title
      userID
      message
      status
      options
      updatedAt
    }
  }
`;
export const onDeleteSystemsMessage = /* GraphQL */ `
  subscription OnDeleteSystemsMessage {
    onDeleteSystemsMessage {
      id
      createdAt
      type
      title
      userID
      message
      status
      options
      updatedAt
    }
  }
`;
export const onCreateUserThingGroup = /* GraphQL */ `
  subscription OnCreateUserThingGroup {
    onCreateUserThingGroup {
      id
      userID
      thingGroupID
      thingGroup {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserThingGroup = /* GraphQL */ `
  subscription OnUpdateUserThingGroup {
    onUpdateUserThingGroup {
      id
      userID
      thingGroupID
      thingGroup {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserThingGroup = /* GraphQL */ `
  subscription OnDeleteUserThingGroup {
    onDeleteUserThingGroup {
      id
      userID
      thingGroupID
      thingGroup {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingGroup = /* GraphQL */ `
  subscription OnCreateThingGroup {
    onCreateThingGroup {
      id
      name
      things {
        items {
          id
          thingGroupID
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingGroup = /* GraphQL */ `
  subscription OnUpdateThingGroup {
    onUpdateThingGroup {
      id
      name
      things {
        items {
          id
          thingGroupID
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingGroup = /* GraphQL */ `
  subscription OnDeleteThingGroup {
    onDeleteThingGroup {
      id
      name
      things {
        items {
          id
          thingGroupID
          thingID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingGroupThing = /* GraphQL */ `
  subscription OnCreateThingGroupThing {
    onCreateThingGroupThing {
      id
      thingGroupID
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingGroupThing = /* GraphQL */ `
  subscription OnUpdateThingGroupThing {
    onUpdateThingGroupThing {
      id
      thingGroupID
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingGroupThing = /* GraphQL */ `
  subscription OnDeleteThingGroupThing {
    onDeleteThingGroupThing {
      id
      thingGroupID
      thingID
      thing {
        thingID
        name
        expiredDate
        type
        serial
        description
        tags
        other
        users {
          nextToken
        }
        console {
          thingID
          name
          createdAt
          updatedAt
        }
        aggregates {
          nextToken
        }
        anomalyDetectors {
          nextToken
        }
        credentials {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingTimeseries = /* GraphQL */ `
  subscription OnCreateThingTimeseries {
    onCreateThingTimeseries {
      thingID
      timestamp
      fieldName
      mean
      count
      min
      max
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingTimeseries = /* GraphQL */ `
  subscription OnUpdateThingTimeseries {
    onUpdateThingTimeseries {
      thingID
      timestamp
      fieldName
      mean
      count
      min
      max
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingTimeseries = /* GraphQL */ `
  subscription OnDeleteThingTimeseries {
    onDeleteThingTimeseries {
      thingID
      timestamp
      fieldName
      mean
      count
      min
      max
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingTask = /* GraphQL */ `
  subscription OnCreateThingTask {
    onCreateThingTask {
      id
      serverID
      type
      enable
      thingID
      userID
      topic
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      field
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingTask = /* GraphQL */ `
  subscription OnUpdateThingTask {
    onUpdateThingTask {
      id
      serverID
      type
      enable
      thingID
      userID
      topic
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      field
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingTask = /* GraphQL */ `
  subscription OnDeleteThingTask {
    onDeleteThingTask {
      id
      serverID
      type
      enable
      thingID
      userID
      topic
      alerts {
        items {
          id
          taskID
          alertID
          createdAt
          updatedAt
        }
        nextToken
      }
      field
      method
      sensitivity
      upperBound
      lowerBound
      threshold
      createdAt
      updatedAt
    }
  }
`;
export const onCreateThingTaskAlert = /* GraphQL */ `
  subscription OnCreateThingTaskAlert {
    onCreateThingTaskAlert {
      id
      taskID
      task {
        id
        serverID
        type
        enable
        thingID
        userID
        topic
        alerts {
          nextToken
        }
        field
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateThingTaskAlert = /* GraphQL */ `
  subscription OnUpdateThingTaskAlert {
    onUpdateThingTaskAlert {
      id
      taskID
      task {
        id
        serverID
        type
        enable
        thingID
        userID
        topic
        alerts {
          nextToken
        }
        field
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteThingTaskAlert = /* GraphQL */ `
  subscription OnDeleteThingTaskAlert {
    onDeleteThingTaskAlert {
      id
      taskID
      task {
        id
        serverID
        type
        enable
        thingID
        userID
        topic
        alerts {
          nextToken
        }
        field
        method
        sensitivity
        upperBound
        lowerBound
        threshold
        createdAt
        updatedAt
      }
      alertID
      alert {
        id
        userID
        type
        name
        token
        enable
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
