<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ $t("Edit location") }}</p>
        </header>
        <section class="modal-card-body" style="text-align: start">
            <b-field :label="`Select province` | t" required>
                <b-select
                    :placeholder="`Select province` | t"
                    expanded
                    v-model="province"
                >
                    <option
                        v-for="item in provinceList"
                        :value="item"
                        :key="item"
                    >
                        {{ item }}
                    </option>
                </b-select>
            </b-field>

            <b-field :label="`Select district` | t" required>
                <b-select
                    :placeholder="`Select district` | t"
                    expanded
                    v-model="district"
                >
                    <option
                        v-for="item in districtList"
                        :value="item"
                        :key="item"
                    >
                        {{ item }}
                    </option>
                </b-select>
            </b-field>

            <b-field :label="`Select subdistrict` | t" required>
                <b-select
                    :placeholder="`Select subdistrict` | t"
                    expanded
                    v-model="subdistrict"
                >
                    <option
                        v-for="item in subdistrictList"
                        :value="item"
                        :key="item"
                    >
                        {{ item }}
                    </option>
                </b-select>
            </b-field>

            <!-- <b-field :label="`Select thing ID` | t" required>
                <b-select
                    :placeholder="`Select thing ID` | t"
                    expanded
                    v-model="anomalyDetectorTask.thingID"
                >
                    <option
                        v-for="item in thingList"
                        :value="item.thingID"
                        :key="item.thingID"
                    >
                        {{ item.thingID }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="`Select field` | t" required>
                <b-select
                    :placeholder="`Select field` | t"
                    expanded
                    v-model="anomalyDetectorTask.field"
                >
                    <option v-for="item in fieldList" :value="item" :key="item">
                        {{ item }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="`Select method` | t" required>
                <b-select
                    :placeholder="`Select method` | t"
                    expanded
                    v-model="anomalyDetectorTask.method"
                >
                    <option
                        v-for="item in methodList"
                        :value="item"
                        :key="item"
                    >
                        {{ item }}
                    </option>
                </b-select>
            </b-field>
            <b-field :label="`Sensitivity` | t">
                <b-slider
                    :step="10"
                    v-model="anomalyDetectorTask.sensitivity"
                    :custom-formatter="(val) => (val / 100).toString()"
                ></b-slider>
            </b-field>
            <b-field :label="`Upper bound` | t">
                <b-numberinput
                    :step="1"
                    :controls="false"
                    v-model="anomalyDetectorTask.upperBound"
                >
                </b-numberinput>
            </b-field>
            <b-field :label="`Lower bound` | t">
                <b-numberinput
                    :step="1"
                    :controls="false"
                    v-model="anomalyDetectorTask.lowerBound"
                >
                </b-numberinput>
            </b-field>
            <b-field :label="`Threshold` | t">
                <b-slider
                    :step="10"
                    v-model="anomalyDetectorTask.threshold"
                    :custom-formatter="(val) => (val / 100).toString()"
                ></b-slider>
            </b-field>
            <b-field :label="`Select alert` | t"></b-field>
            <b-field v-for="(item, index) in alertList" :key="index">
                <b-checkbox
                    v-model="anomalyDetectorTask.alerts"
                    :native-value="item"
                >
                    {{ item.name }}
                </b-checkbox>
            </b-field> -->
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
            <b-button :label="`Cancel` | t" @click="$parent.close()" />
            <b-button
                :label="`Confirm` | t"
                type="is-primary"
                @click="onConfirm"
            />
        </footer>
    </div>
</template>

<script>
import api_thailand_new from "@/assets/api_thailand_new.json";
import province_data from "@/assets/province.json";
import tambon_data from "@/assets/tambon.json";
import axios from "axios";

export default {
    name: "EditWeatherLocation",
    data() {
        return {
            province: null,
            provinceList: [],
            district: null,
            districtList: [],
            subdistrict: null,
            subdistrictList: [],
            loading: false,
            // anomalyDetectorTask: {
            //     id: null,
            //     thingID: null,
            //     AnomalyDetectorID: null,
            //     topic: "data",
            //     field: null,
            //     enable: true,
            //     method: null,
            //     sensitivity: null, //0-1
            //     upperBound: null,
            //     lowerBound: null,
            //     threshold: 80,
            //     alerts: [],
            // },
            // fieldList: ["DO", "pH", "EC", "Temp"],
            // methodList: ["FENCE", "MAGNITUDE"],
        };
    },
    computed: {
        // ...mapGetters({
        //     thingList: "user/thingList",
        //     alertList: "user/alertList",
        //     isConnected: "mq/isConnected",
        //     ruleList: "anomalyDetectorTask/ruleList",
        // }),
    },
    watch: {
        province: {
            handler: function(value) {
                this.district = null;
                this.getDistrict(value);
            },
        },
        district: {
            handler: function(value) {
                this.subdistrict = null;
                this.getSubdistrict(value);
            },
        },
    },
    mounted() {
        this.getProvinceList();
    },
    methods: {
        getProvinceList() {
            this.provinceList = province_data;
        },
        getDistrict(province) {
            let districtList = api_thailand_new.filter((element) => {
                return element.ProvinceThai === province;
            });
            districtList = districtList.map((i) => i.DistrictThai);
            this.districtList = [...new Set(districtList)];
        },
        getSubdistrict(district) {
            let subdistrictList = api_thailand_new.filter((element) => {
                return element.DistrictThai === district;
            });
            subdistrictList = subdistrictList.map((i) => i.TambonThai);
            this.subdistrictList = [...new Set(subdistrictList)];
        },
        async onConfirm() {
            console.log("onConfirm");

            if ([null, undefined, ""].indexOf(this.province) >= 0) {
                return;
            }
            if ([null, undefined, ""].indexOf(this.district) >= 0) {
                return;
            }
            if ([null, undefined, ""].indexOf(this.subdistrict) >= 0) {
                return;
            }

            try {
                this.loading = true;
                const tambonID = this.getTambonID(this.subdistrict);
                const latlon = this.getLatLon(tambonID);
                await this.$store.dispatch("user/updateWeatherLocation", {
                    lat: latlon.lat,
                    lon: latlon.lon,
                    displayName: `${this.subdistrict} ${this.district} ${this.province}`,
                });
                this.loading = false;
                this.$parent.close();
            } catch (error) {
                this.loading = false;
            }
        },
        getTambonID(subdistrict) {
            let x = api_thailand_new.find((x) => {
                return x.TambonThai === subdistrict;
            });
            return x.TambonID;
        },
        getLatLon(tambonID) {
            let x = tambon_data.find((x) => {
                return x.TA_ID == tambonID;
            });

            return {
                lat: x.LAT,
                lon: x.LONG
            }
        },
        // async onAddRule() {
        //     if (
        //         [null, undefined, ""].indexOf(
        //             this.anomalyDetectorTask.thingID
        //         ) >= 0
        //     ) {
        //         return;
        //     }
        //     if (
        //         [null, undefined, ""].indexOf(this.anomalyDetectorTask.topic) >=
        //         0
        //     ) {
        //         return;
        //     }
        //     if (
        //         [null, undefined, ""].indexOf(this.anomalyDetectorTask.field) >=
        //         0
        //     ) {
        //         return;
        //     }
        //     if (
        //         [null, undefined, ""].indexOf(
        //             this.anomalyDetectorTask.method
        //         ) >= 0
        //     ) {
        //         return;
        //     }
        //     if (
        //         [null, undefined, ""].indexOf(
        //             this.anomalyDetectorTask.upperBound
        //         ) >= 0
        //     ) {
        //         return;
        //     }
        //     if (
        //         [null, undefined, ""].indexOf(
        //             this.anomalyDetectorTask.lowerBound
        //         ) >= 0
        //     ) {
        //         return;
        //     }
        //     if (
        //         [null, undefined, ""].indexOf(
        //             this.anomalyDetectorTask.threshold
        //         ) >= 0
        //     ) {
        //         return;
        //     }

        //     if (this.anomalyDetectorTask.alerts.length <= 0) {
        //         return;
        //     }

        //     console.log("add rule");
        //     this.anomalyDetectorTask.sensitivity =
        //         this.anomalyDetectorTask.sensitivity / 100;
        //     this.anomalyDetectorTask.threshold =
        //         this.anomalyDetectorTask.threshold / 100;
        //     this.$store.dispatch(
        //         "anomalyDetectorTask/addRule",
        //         this.anomalyDetectorTask
        //     );
        //     this.$parent.close();
        // },
    },
};
</script>
