<template>
    <div
        class="p-2 is-flex is-flex-direction-column is-align-content-flex-start"
        style="width:100%"
    >
        <div class="has-text-left">
            <strong>
                {{ systemsMessage.title }}
            </strong>
        </div>
        <div class="has-text-left">
            {{ systemsMessage.message }}
        </div>
        <div class="is-flex is-justify-content-space-between">
            <b-button size="is-small" type="is-danger" @click="onOK">
                OK
            </b-button>
            <b-button size="is-small" @click="onCancel">
                Cancel
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: "SystemsMessageAlert",
    props: {
        systemsMessage: {
            required: true,
            type: Object,
        },
    },
    methods: {
        async onOK() {
            if (this.systemsMessage.options) {
                const option = this.systemsMessage.options[0];
                console.log(option);
                if (option) {
                    const opt = JSON.parse(option);
                    switch (opt.type) {
                        case "REQUESTACCESS":
                            console.log("REQUESTACCESS");
                            {
                                await this.$store.dispatch(
                                    "addDevice/pair",
                                    opt.payload
                                );
                                await this.$store.dispatch(
                                    "systemsMessage/remove",
                                    {
                                        id: this.systemsMessage.id
                                    }
                                );
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
            console.log("onOK");
        },
        onCancel() {
            console.log("onCancel");
        },
    },
};
</script>
